import { action, observable } from "mobx";
import React from "react";

import {uuidv4} from "../general";
import {AlertOptions} from "@ionic/core";
import {ignore} from "../libs/mobx-sync";

export type Notification = {
    content: string,
    title: string,
    open: boolean,
    id: string,
    props: AlertOptions
}

export class NotificationService {
    @observable
    @ignore
    notifications = [] as Array<Notification>;


    @action
    addNotification(content: string, title = "", props: AlertOptions = {}) {
        this.notifications.push({
            props: props,
            content,
            title,
            open: true,
            id: uuidv4()
        });
    }
}

export const NotificationContext = React.createContext(undefined) as any as React.Context<NotificationService>;
export default NotificationContext;
