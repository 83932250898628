import React from "react";
import {IonButton, IonModal} from "@ionic/react";
import {observer, useLocalStore} from "mobx-react-lite";
import {ComponentRef, ModalOptions} from "@ionic/core";

const DialogButton = ({title, props: {onClick: OnClickDefault = () => false, ...props} = {}, onClickDialog = () => false, type}: any) => {
    const onClick = React.useCallback(() => {
        if (OnClickDefault)
            OnClickDefault(type ?? title);
        onClickDialog(type ?? title);
    }, [onClickDialog, OnClickDefault, type, title]);
    return <IonButton onClick={onClick} {...props}>
        {title}
    </IonButton>
}

export default observer(
    (({content, buttons, styleContainer = {}, stayOpen = false, onValidate = () => true, ...props}, ref: any) => {
        const [open, setOpen] = React.useState(false);
        const data = useLocalStore(() => ({
            resolve: undefined as any,
            callback: undefined as any,
        }))
        const callResolve = React.useCallback((param) => {
            data.resolve?.(param);
            data.callback?.(param);
            data.resolve = null;
        }, [data.resolve]);

        React.useImperativeHandle(ref, () => ({
            openDialog: (callback?: any) => {
                setOpen(true);
                return new Promise((resolve) => {
                    data.resolve = resolve;
                    data.callback = callback;
                })
            },
            cancel: () => {
                callResolve(null);
                setOpen(false);
            },
            setResolve: resolve => data.resolve = resolve
        }), [setOpen, data, callResolve]);
        const onClose = React.useCallback(() => {
            callResolve(null);

            setOpen(false);
            return false;
        }, [callResolve]);
        const onClickBtn = React.useCallback(async (type) => {
            if (!(await Promise.resolve(onValidate(type)))) {
                return;
            }
            callResolve(type);
            if (!stayOpen)
                setOpen(false);
        }, [callResolve]);
        return <IonModal isOpen={open} onWillDismiss={onClose} {...props}>
            {content}
            <div style={{textAlign: "center", ...styleContainer}}>

                {buttons.map((props) => <DialogButton key={props.type ?? props.title}
                                                      onClickDialog={onClickBtn} {...props}/>
                )}
            </div>
        </IonModal>
    }) as React.FC<Pick<ModalOptions<ComponentRef>, "mode" | "animated" | "id" | "cssClass" | "backdropDismiss" | "keyboardClose" | "enterAnimation" | "leaveAnimation" | "showBackdrop" | "presentingElement" | "delegate" | "swipeToClose"> &
        {
            content: any,
            buttons: Array<{ title: any, type?: any, props?: any }>,
            styleContainer?: React.CSSProperties,
            [key: string]: any
        }>, {
        forwardRef: true
    });

export type TypeDialog = {
    openDialog(callback?: (resp: any) => any): Promise<any>,
    cancel(): void
}
