import React, {useContext} from "react";
import {IonBadge, IonButton, IonButtons, IonIcon, IonLabel, IonTabButton} from "@ionic/react";
import {useLocation, useHistory} from "react-router";
import {cart} from "ionicons/icons";
import {userStoreContext} from "../services/Firebase";
import {observer} from "mobx-react-lite";
import {useIntl} from "react-intl";

const BtnCarrito: React.FC = observer(() => {
    const navigate = useHistory()
    const location = useLocation()
    const userStore = useContext(userStoreContext);
    const intl = useIntl();
    const onClick = React.useCallback(() => {
        console.log("should navigate", location.pathname);
        if (location.pathname !== "/app/carrito")
            navigate.push("/app/carrito");
    }, [location.pathname, navigate]);
    return <IonButtons>
        <IonTabButton
            tab={"carrito"}
            className={"notification-icon-button flex flex-column"}
            onClick={onClick}>
            <IonIcon icon={cart}/>
            <IonLabel>{intl.formatMessage({id: "Carrito"})}</IonLabel>
            <IonBadge color={"danger"}>{userStore.productos.length}</IonBadge>
        </IonTabButton>
    </IonButtons>

});
export default BtnCarrito;
