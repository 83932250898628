import * as Color from 'color';
import {Theme} from "@material-ui/core";

const defaults = {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8'
};

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : null;
}

export function CSSTextGenerator(colors: Theme,otherColors: typeof defaults) {

    let
        primary,
        secondary,
        tertiary,
        success,
        warning,
        danger,
        dark,
        medium,
        light
    ;
    primary = colors.palette.primary.main || defaults.primary;
    secondary = colors.palette.secondary.main || defaults.secondary;
    tertiary = colors.palette.info.main || defaults.tertiary;
    success = colors.palette.success.main || defaults.success;
    warning = colors.palette.warning.main || defaults.warning;
    danger = colors.palette.error.main || defaults.danger;
    dark = otherColors.dark || defaults.dark;
    medium = otherColors.medium || defaults.medium;
    light =otherColors.light ||  defaults.light;
    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};
    --ion-background-color: ${light};
    --ion-text-color: ${dark};
    --ion-toolbar-background-color: ${contrast(light, 0.1)};
    --ion-toolbar-color: ${colors.palette.primary.contrastText};
    --ion-item-background-color: ${contrast(light, 0.3)};
    --ion-item-text-color: ${contrast(dark, 0.3)};
    --ion-color-primary: ${primary};
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-color-primary-rgb: ${hexToRgb(primary)};
    --ion-color-primary-contrast: ${colors.palette.primary.contrastText};
    --ion-color-primary-contrast-rgb: ${hexToRgb(colors.palette.primary.contrastText)};
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio)};
    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${hexToRgb(secondary)};
    --ion-color-secondary-contrast: ${colors.palette.secondary.contrastText};
    --ion-color-secondary-contrast-rgb: ${hexToRgb(colors.palette.secondary.contrastText)};
    --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: ${hexToRgb(tertiary)};
    --ion-color-tertiary-contrast: ${colors.palette.info.contrastText};
    --ion-color-tertiary-contrast-rgb: ${hexToRgb(colors.palette.info.contrastText)};
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
    --ion-color-success: ${success};
    --ion-color-success-rgb: ${hexToRgb(success)};
    --ion-color-success-contrast: ${colors.palette.success.contrastText};
    --ion-color-success-contrast-rgb: ${hexToRgb(colors.palette.success.contrastText)};
    --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio)};
    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${hexToRgb(warning)};
    --ion-color-warning-contrast: ${colors.palette.warning.contrastText};
    --ion-color-warning-contrast-rgb: ${hexToRgb(colors.palette.warning.contrastText)};
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${hexToRgb(danger)};
    --ion-color-danger-contrast: ${colors.palette.error.contrastText};
    --ion-color-danger-contrast-rgb: ${hexToRgb(colors.palette.error.contrastText)};
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${hexToRgb(dark)};
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-rgb: ${hexToRgb(contrast(dark))};
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${hexToRgb(medium)};
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-rgb: ${hexToRgb(contrast(medium))};
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
    --ion-color-light: ${light};
    --ion-color-light-rgb: ${hexToRgb(contrast(light))};
    --ion-color-light-contrast: $${contrast(light)};
    --ion-color-light-contrast-rgb: ${hexToRgb(contrast(light))};
    --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio)};`;
}

function contrast(colorOld, ratio = 5) {
    let color = Color(colorOld);
    return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
}
