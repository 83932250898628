import React from 'react';
import {connect} from 'react-redux';
import SidenavContent from './SidenavContent';
import UserInfo from '../UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from '../../constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from '../../stateManager/ducks/settings';
import {observer} from "mobx-react";
import {userStoreContext} from "../../services/Firebase";
import {SwipeableDrawer} from "@material-ui/core";

@observer
class SideNav extends React.Component<any> {
    static contextType = userStoreContext;
    context!: React.ContextType<typeof userStoreContext>
    onToggleCollapsedNav = () => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth);
        });
        this.props.updateWindowWidth(window.innerWidth);
    }

    render() {
        if (this.context.isLoadingData || (this.context.configuracion?.sistema === 24 && !this.context.isAuthenticated)) {
            document.title = 'Business Master';

            return <></>;
        }

        // document.title = `Business Master - Sucursal: ${userStore.sucursal.empresa.trim()}, Usuario: ${userStore.user.nomusu.trim()}, Terminal: ${userStore.computerName.trim()}`;

        const {navCollapsed, drawerType, width, navigationStyle} = this.props;
        let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : (drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex');
        let type: "permanent" | "temporary" | "persistent" = 'permanent';
        if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
            type = 'temporary';
        }

        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = 'temporary';
        }
        let isOpen = type.includes("temporary") ? navCollapsed : true;
        let classFixElectron = "";
        if (type.includes("temporary")) {
            drawerStyle = 'd-none';
            classFixElectron = "rouded";
        }
        return (
            <div className={`app-sidebar  ${drawerStyle}`}>
                <SwipeableDrawer
                    className={`app-sidebar-content ${classFixElectron}`}
                    variant={type}
                    open={isOpen}
                    onClose={this.onToggleCollapsedNav}
                    onOpen={this.onToggleCollapsedNav}
                    classes={{
                        paper: 'side-nav',
                    }}
                    anchor={"left"}
                >
                    <UserInfo/>
                    <SidenavContent onToggleCollapsedNav={this.onToggleCollapsedNav}/>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = ({settings}: any) => {
    const {navCollapsed, drawerType, width, navigationStyle} = settings;
    return {navCollapsed, drawerType, width, navigationStyle};
};

export default connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav);

