import React from 'react';
import {IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './paginaPrincipal.css';
import {DeepLinkContext, DefaultHeader} from "../App";
import {observer, useAsObservableSource} from "mobx-react-lite";
import {reaction} from "mobx";
// @ts-ignore
import {atomDark as AtomDark} from "react-syntax-highlighter/dist/esm/styles/prism";
import Dialog, {TypeDialog} from "../components/Dialog";

import {Avatar, Button, Grid} from '@material-ui/core';
import {makeStyles, Theme} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import TextInput from "../components/TextInput";
import BtnCarrito from "../components/BtnCarrito";
import IntlMessages from "../util/IntlMessages";
import {userStoreContext} from "../services/Firebase";
import {useHistory} from "react-router";


const useStyles = makeStyles((theme: Theme) => ({

    toolbar: {
        padding: `0 ${theme.spacing(5)}px`,
    },
}));
const PaginaPrincipal: React.FC = observer(() => {
    //#region variables
    const userStore = React.useContext(userStoreContext);
    const deepLink = useAsObservableSource(React.useContext(DeepLinkContext));
    const refDialogMensajes = React.useRef<TypeDialog>();
    const refDialogBuzonSugerencias = React.useRef<TypeDialog>();
    const history = useHistory();
    const intl = useIntl();

    const btnDataMenu = [
        {detail: intl.formatMessage({id: "dashboard.orders"}), type: 0, title: <i className="fa fa-envelope"/>},
        {detail: intl.formatMessage({id: "Estados de cuentas"}), type: 1, title: <i className="fa fa-envelope"/>},
        {detail: intl.formatMessage({id: "Mensajes"}), type: 2, title: <i className="fa fa-envelope"/>},
        {detail: intl.formatMessage({id: "Boletines informativos"}), type: 3, title: <i className="fa fa-envelope"/>},
        {detail: intl.formatMessage({id: "Buzon de sugerencias"}), type: 4, title: <i className="fa fa-envelope"/>},
        {detail: intl.formatMessage({id: "Rastrear compra"}), type: 5, title: <i className="fa fa-envelope"/>},
    ]
    const classes = useStyles();

    //#endregion

    //#region ciclo de vida
    React.useEffect(() => {

        return reaction(() => deepLink.value, () => {
            console.log("deepLink", deepLink);
        })
        // eslint-disable-next-line
    }, []);


    //#endregion

    //#region Metodos


    const onClickGeneralMenu = React.useCallback((type: number) => () => {
        switch (type) {
            case 0:
                history.push("/app/productos");

                /*  refDialogPedido.current?.openDialog().then(resp => {
                      if ((resp ?? null) === null) {
                          return;
                      }
                      console.log("cambiar a pantalla: " + resp)
                  });*/
                break;
            case 2: {
                refDialogMensajes.current?.openDialog().then(resp => {
                    if ((resp ?? null) == null)
                        return;
                    onClickGeneralMenu(resp)();
                });
                break;
            }
            case 4: {
                refDialogBuzonSugerencias.current?.openDialog().then(resp => {
                    if ((resp ?? null) == null)
                        return;
                    onClickGeneralMenu(resp)();
                });
                break;
            }
        }
    }, [history]);
    const onEnviarMensaje = React.useCallback(() => {
        userStore.notificationService.addNotification("Mensaje", "", {
            inputs: [{
                name: "msg",
                type: "textarea",
                placeholder: intl.formatMessage({id: "introdusca el mensaje"})
            }],
            buttons: [
                {
                    text: "Ok",
                    handler: (data) => {
                        console.log("sendMessage", data);
                        userStore.notificationService.addNotification("", intl.formatMessage({id: "MensajeEnviadoSatisfactoriamente"}));
                    }
                }
            ]
        })
    }, []);
    //#endregion

    const title = "Opciones";

    return (
        <IonPage>
            <DefaultHeader/>
            <IonHeader>
                <IonToolbar className={classes.toolbar}>
                    <div slot={"start"}>
                        {/*<Avatar>
                        <img src={"/hsicon.jpeg"}/>
                    </Avatar>*/}
                        <div
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        >
                            <img src={"/hidalsoft.png"}/>
                        </div>
                    </div>
                    <IonTitle>{title}</IonTitle>
                    <div slot={"end"}>
                        <BtnCarrito/>
                    </div>

                </IonToolbar>
            </IonHeader>

            <IonContent>


                <Dialog
                    content={<IonContent>
                        <div className="content center">
                            <Grid className={"wrapper"} container spacing={3} direction={"row"}>
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <IntlMessages id={"Bandeja de entrada"}/>
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <IntlMessages id={"Mensajes no leidos"}/>
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <IntlMessages id={"Borradores"}/>
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                        onClick={onEnviarMensaje}
                                    >
                                        <IntlMessages id={"Enviar mensaje"}/>
                                    </Button>
                                </Grid>
                            </Grid>

                        </div>
                    </IonContent>}
                    buttons={btnDataMenu.filter(btn => btn.type !== 2)}
                    ref={refDialogMensajes}
                />
                <Dialog
                    ref={refDialogBuzonSugerencias}
                    content={<IonContent>
                        <div className="col-12 h-100">
                            <strong>{intl.formatMessage({id:"Sugerencias"})}</strong>
                            <TextInput
                                multiline
                                style={{height: "calc(100% - 46px)"}}
                            />
                        </div>
                    </IonContent>}
                    buttons={[
                        {
                            title: intl.formatMessage({id:"Aceptar"}),
                            type: 1
                        },
                        {
                            title: intl.formatMessage({id:"Cancelar"}),
                            type: 0
                        }
                    ]}
                />

                <div className={"container"}>

                    <Grid className={'pruebaRow'} spacing={3} container direction={"row"}>
                        {btnDataMenu.map((btn) => <Grid key={btn.type} item xs={6}>
                            <IonButton
                                onClick={onClickGeneralMenu(btn.type)}>{btn.title}&nbsp; {btn.detail}
                            </IonButton>
                        </Grid>)}

                    </Grid>
                </div>
            </IonContent>
        </IonPage>
    );
});

export default PaginaPrincipal;
