import {IonPage, IonRouterOutlet} from "@ionic/react";
import {useRouteMatch} from "react-router";
import PaginaPrincipal from "../paginaPrincipal";
import React from "react";
import {PrivateRoute} from "../../components/PrivateRoute";

const Carrito = React.lazy(() => import("../carrito"));
const Productos = React.lazy(() => import("../productos"));
const ProductDetail = React.lazy(() => import("../productos/detalle"));

const AppContainer  = () => {
    const match = useRouteMatch();

    return <IonPage>

            <IonRouterOutlet>

                <PrivateRoute path={`${match.path}/menu`} component={PaginaPrincipal}/>
                {/*<PrivateRoute path={`${match.path}/pedido`} component={Pedido}/>*/}
                <PrivateRoute path={`${match.path}/productos/:codpro/:coduni`} component={ProductDetail}/>
                <PrivateRoute path={`${match.path}/productos`} component={Productos}/>
                <PrivateRoute path={`${match.path}/carrito`} component={Carrito}/>
                {/*<Redirect to={`${match.path}/menu`}/>*/}

            </IonRouterOutlet>

    </IonPage>;
}
export default AppContainer;