import React, {Component, Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';

import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from '../../util/CustomScrollbars';
import {injectIntl} from '../injectIntl';
import userStore from '../../mobx/UserStore';
import { observable } from 'mobx';
import {observer} from 'mobx-react';
import Input from '../TextInput';
import {busquedaInteligente, guid} from '../../util/api/General';
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import $ from "jquery";
import {Menu} from "../../mobx/flowtypes";
import _ from "lodash";
import {FirebaseContext} from "../../services/Firebase";
import {withRouter} from "util/withRouter";


// @ts-ignore
@injectIntl({
    withRef: true
})
@observer
class SidenavContent extends Component<any, any> {
    @observable
    data = {
        filtro: '',
        keys: [] as any
    };
    private disableClick: boolean = false;

    get id() {
        return guid();
    }

    componentDidMount() {
        if (!userStore.menu || userStore.menu.length === 0) {
            this.forceUpdate();
        }
        setImmediate(() => {
            userStore.menu = userStore.generateMenu(this.props.intl);
            this.data.keys = [...new Set(this.getKeys(userStore.menu))];
            /*if (userStore.user.admin || userStore.user.CONTROL || userStore.user.presentarmenucompleto) {
                            SalvaMenuGeneral(this.getMenuURLS())
                        }*/

            this.forceUpdate();
        });

        setImmediate(() => {
            this.refresh();
        });


    }

    refresh = () => {
        const {location} = this.props;
        const that = this;
        const pathname = `#${location.pathname}`;// get current path

        const subMenuLi: any = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event: React.MouseEvent) {
                event.preventDefault();
                event.stopPropagation();
            };
        }

        const menuLi: any = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event: React.MouseEvent) {
                event.preventDefault();
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open');
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            };
        }

        const activeLi: HTMLLinkElement = document.querySelector(`a[href="${pathname}"]`) as any;// selects current a element
        activeLi && setImmediate(() => {
            // @ts-ignore
            scrollIntoView(activeLi, {
                // @ts-ignore
                behavior: 'instant',
                scrollMode: 'if-needed',

            });
        });
        try {
            const activeNav = this.closest(activeLi, 'ul'); // selects closest ul
            // @ts-ignore
            if (activeNav?.classList?.contains('sub-menu')) {
                // @ts-ignore
                this.closest(activeNav, 'li')
                    .classList
                    .add('open');
            } else {
                // @ts-ignore
                this.closest(activeLi, 'li')
                    ?.classList
                    ?.add('open');
            }
        } catch (error) {
            console.log("error", error);
        }

        $('.menu.open')
            .removeClass('open');
        this.getParentMenu(activeLi);
    };


    getParentMenu = (element: any) => {
        const menu = $(element)
            .parent()
            .closest('.menu');
        if (menu.length > 0) {
            menu.addClass('open');
            this.getParentMenu(menu);
        }
    };

    closest(el: Element, selector: string) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
                // @ts-ignore
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                // @ts-ignore
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                // @ts-ignore
                el = parent;
            }
        } catch (error) {

        }

        return null;
    }

    onRouteChanged() {
        const {location} = this.props;
        const pathname = `#${location.pathname}`;// get current path
        const activeLi = document.querySelector(`a[href="${pathname}"]`);// selects current a element
        if (
            $(activeLi as any)
                .closest('.menu')
                .hasClass('open')
        ) {
            return;
        }
        $('.menu.open')
            .removeClass('open');
        this.getParentMenu(activeLi);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
        if (this.props.intl.locale !== prevProps.intl.locale) {
            userStore.menu = userStore.generateMenu(this.props.intl);
            this.data.keys = [...new Set(this.getKeys(userStore.menu))];
            this.forceUpdate();
        }
        let that = this;

        const subMenuLi: any = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event: MouseEvent) {
                event.stopPropagation();
            };
        }

        const menuLi: any = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event: MouseEvent) {
                if (that.disableClick) {
                    return;
                }
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open');
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            };
        }
    }

    getMenu(data: Menu[], filtro: string) {
        this.disableClick = !!filtro;
        if (filtro) {
            // @ts-ignore
            let keys = this.data.keys;
            data = busquedaInteligente(data, filtro, keys, 0.25, undefined).map(dt => {
                return dt.item;
            });

        }
        return data.map((menu) => {
            if (
                // !userStore.user.admin && !userStore.user.presentarmenucompleto &&
                (menu.child ? false : (menu.url ? userStore.menuPermisos.indexOf(menu.url) === -1 : false))) {
                // return;

            }
            if (menu.needAuth) {
                if (userStore.configuracion?.sistema !== 24 && !userStore.isAuthenticated)
                    return <React.Fragment key={menu.key}/>;
            }

            if (menu.child) {

                let childs = this.getMenu(menu.child, filtro)
                    .filter((dt) => !!dt);
                if ((childs && childs.length === 0) || !childs) {
                    return <></>;
                }
                return <li key={menu.key}
                           className={`menu collapse-box ${filtro && 'open'}`}>
                    <a onClick={event => event.preventDefault()}>
                        <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                        <span className="nav-text">{menu.name}</span>
                    </a>
                    <ul className="sub-menu">{childs}</ul>
                </li>;
            } else {

                return <li key={menu.key} onClick={(event) => {
                    event.stopPropagation && event.stopPropagation();
                }}>
                    <NavLink ref={(ref: HTMLAnchorElement) => {
                        if (ref) {
                            ref.onclick = (event) => {

                                let resp = menu.onClick && menu.onClick(event);
                                if (resp) {
                                    event.preventDefault();
                                }
                                this.data.filtro = '';
                                setImmediate(() => {
                                    this.props.onToggleCollapsedNav();
                                    this.onRouteChanged();
                                });
                            };
                        }
                    }} className="prepend-icon" to={menu.url as string}>
                        <img src={menu.icon}/>
                        <span
                            className="nav-text">{menu.name}</span>
                    </NavLink>
                </li>;
            }
        });
    }

    // @ts-ignore
    findNode(nombre: string, menu: Menu) {
        var i,
            currentChild: Menu,
            result;
        if (menu.name.trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(nombre.trim()
                .toLowerCase())) {
            return menu;
        } else if (menu.child) {

            // Use a for loop instead of forEach to avoid nested functions
            // Otherwise "return" will not work properly
            for (i = 0; i < menu.child.length; i += 1) {
                currentChild = menu.child[i];

                // Search in the current child
                result = this.findNode(nombre, currentChild);

                // Return the result if the node has been found
                if (result !== false) {
                    return result;
                }
            }


        }
        // The node has not been found and we have no more options
        return false;
    }

    getMenuURLS() {
        let cont = 1;
        // @ts-ignore
        let prueba = (father: Menu) => (dt: Menu, index) => {

            if (dt.child) {
                return dt.child.map(prueba(dt));
            }
            return {
                nombre: dt.url,
                texto: dt.name,
                nombrePadre: dt.url?.slice(0, dt.url?.lastIndexOf('/')),
                textoPadre: father ? father.name : '',
                posicion: index + 1,
                orden: cont++
            };
        };

        return _.flattenDeep(userStore.menu.map(prueba(undefined as any)));

    }

    getKeys(menu: Menu) {
        function getKeys(menu: Menu): any {

            if (Array.isArray(menu)) {
                return menu.map(getKeys);
            } else if (menu.child) {
                return menu.child.map((child: Menu, index: number) =>
                    getKeys(child)
                        .map((k: string) => `child[${index}].` + k));
            } else {
                return ['name'];
            }
        }

        return _.flattenDeep(getKeys(menu));
    }

    render() {
        const {
            intl
        } = this.props;

        // <editor-fold desc="prueba" defaultstate="collapsed">

        //</editor-fold>
        return (
            <Fragment>
                <div style={{
                    padding: '0 6px',
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    background: 'white'
                }}>
                    <Input data={this.data} placeholder={intl.formatMessage({id: 'pages.general.search'}) + '...'}
                           campo={'filtro'} editable={true}/>
                </div>
                <ul className="nav-menu">
                    <li className="nav-header"><IntlMessages id="sidebar.main"/></li>
                </ul>
                <CustomScrollbars className="scrollbar">
                    <ul className="nav-menu">
                        {/*<li className="nav-header"><IntlMessages id="sidebar.main"/></li>*/}
                        {this.getMenu(userStore.menu, this.data.filtro)}
                    </ul>
                </CustomScrollbars>
                <HeaderIcon/>
            </Fragment>
        );
    }
}

export const HeaderIcon = () => {
    const firebase = useContext(FirebaseContext);
    const [icon, setIcon] = React.useState(null as any);
    React.useEffect(() => {
        let isCancelled = false;
        firebase.getIcon().then((resp) => {
            if (!isCancelled) setIcon(resp);
        });
        return () => {
            isCancelled = true;
        }
    }, [firebase]);
    return <img
        //height={56}
        style={{
            //height: 56,
            height: 'fit-content'
        }}
        src={icon} alt={""}/>;
};

export default withRouter(SidenavContent);
