import React from 'react';
import {userStoreContext} from "services/Firebase";
import "./Register.scss";
import {reaction, set} from "mobx";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import Dialog, {TypeDialog} from "components/Dialog";
import lorem from "../../lorem.json";
import {DefaultHeader} from "App";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";
import {Avatar, Button, Grid} from "@material-ui/core";
import {Components} from "@ionic/core";
import {useIntl} from "react-intl";
import {observer, useLocalStore} from "mobx-react-lite";
import {
    CarritosTerminosTiposDatos,
    CarritoUsuarioActualiza,
    CarritoValidaUsuarioValidoParaCrear
} from "../../../util/api/RequestApi";
import DialogSolicitudCredito from "./DialogSolicitudCredito";
import LanguageSwitcherContainer from "../../../components/LanguageSwitcher";
import TextField from "../../../components/TextInput";
import ReCAPTCHA from "react-google-recaptcha";
import {DEFAULT_PAGE} from "../../../constants/ApiConfig";
import IntlMessages from "../../../util/IntlMessages";
import _ from "lodash";
import {onLoginGeneral, removeSpaceInData} from "../../../util/api/General";
import BtnCarrito from "../../../components/BtnCarrito";
import {useHistory} from "react-router";


const columnas = {
    user: "user",
    pass: "pass",
    nombre: "nombre",
    nombreUsuario: "idUsuario",
    email: "email",
    tipo: "tipo",
    confirmContrasena: "confirmContrasena",
    contrasena: "contrasena",
    nombreDireccion: "nombreDireccion",
    direccion: "direccion",
    direccion2: "direccion2",
    ciudad: "ciudad",
    provincia: "estado",
    telefonoResidencial: "telefono",
    telefonoOficina: "telefono",
    telefonoMovil: "telefono2",
    correoDeRespaldo: "emalrepaldo",//TODO: verficar cada campo
    noDeCeddula: "rnccedula",
    primerApellido: "apellidos",
    nombreContactoEmergencia: "nombreContacto",
    apellidoContactoEmergencia: "apellidoContactoEmergencia", //TODO: agregarlo a nombre de contacto
    telefonoContactoEmergencia: "telefonoContacto",
    nombreRepresentante: "nombreContacto",
    placa: "placa",
    condicion: "condiciondespacho",
    whatsapp: "whatsa",
    taxId: "taxId",
    weChat: "wechat",
    skype: "skype",
    //TODO: no estan rnc ni cedula en la bd
    //TODO:agregar codigo postal
};
const useStyles = makeStyles((theme: Theme) => ({
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500]
    },
    toolbar: {
        padding: `0 ${theme.spacing(5)}px`,
    },
    searchButton: {}
}));


function shouldDisplay(value) {
    return !value ? undefined : "none";
}

function isRequired(value) {
    return {
        required: !value
    };
}

let defaultState = {
    apellido: false,
    rnc: true,
    cedula: false,
    nombreRepresentante: true,
    nombreEmpresa: false,
    nombreEmpleado: false,
    telefonoOficina: true,
    telefonoResidencial: false,
    contactoEmergencia: false,
    placa: true,
    whatsapp: true,
    taxId: true,
    weChat: true,
    skype: true,
};
let defaultStateData = {
    [columnas.user]: "",
    [columnas.pass]: "",
    [columnas.email]: "",
    [columnas.nombre]: "",
    [columnas.nombreDireccion]: "",
    [columnas.nombreUsuario]: "",
    [columnas.direccion]: "",
    [columnas.direccion2]: "",
    [columnas.ciudad]: "",
    [columnas.provincia]: "",
    [columnas.telefonoResidencial]: "",
    [columnas.telefonoMovil]: "",
    [columnas.correoDeRespaldo]: "",
    [columnas.noDeCeddula]: "",
    [columnas.primerApellido]: "",
    [columnas.nombreContactoEmergencia]: "",
    [columnas.apellidoContactoEmergencia]: "",
    [columnas.telefonoContactoEmergencia]: "",
    [columnas.placa]: "",
    [columnas.condicion]: 0,
    [columnas.contrasena]: "",
    [columnas.confirmContrasena]: "",
    [columnas.whatsapp]: "",
    [columnas.taxId]: "",
    [columnas.weChat]: "",
    [columnas.skype]: "",
    [columnas.tipo]: 0,
    token: "",
    terminos:""
};
const Tab1: React.FC = observer(() => {
    //#region variables
    const userStore = React.useContext(userStoreContext);
    const refDialog = React.useRef<TypeDialog>();
    const refDialogActivoEmpresa = React.useRef<TypeDialog>();
    const refDialogCondicionesDespacho = React.useRef<TypeDialog>();
    const refDialogSolicitudCredito = React.useRef<TypeDialog>();
    const formProductor = React.useRef<HTMLFormElement>();
    const refRecaptcha = React.useRef<any>();
    const refDialog2 = React.useRef<TypeDialog>();
    const refDialogTipoSuplidor = React.useRef<TypeDialog>();
    const refTxtPassword = React.useRef<any>();
    const refTxtUsu = React.useRef<any>();
    const router = useHistory();
    const data = useLocalStore(() => defaultStateData);

    const [campos, setCampos2] = React.useState(defaultState);
    const classes = useStyles();
    const intl = useIntl();

    const setCampos = React.useCallback(newData => {
        setCampos2((data) => ({
            ...data,
            ...newData
        }));
    }, [setCampos2]);


    //#endregion
    //#region life cycle
    React.useEffect(() => {
//TODO: revisar para redireccionar
        return reaction(() => userStore.isLoadingData, () => {
            if (userStore.isLoadingData)
                return;
            if (userStore.isAuthenticated)
                router.push(DEFAULT_PAGE);
        }, {fireImmediately: true});
        // eslint-disable-next-line
    }, [router]);
    //#endregion
    //#region metodos
    const onChange = React.useCallback((campo) => (event: CustomEvent) => {
            data[campo] = event.detail.value;

            switch (campo) {
                case columnas.confirmContrasena: {
                    (refTxtPassword.current as Components.IonInput)
                        .getInputElement()
                        .then(
                            input => input.setCustomValidity("")
                        );
                    break;
                }
                case columnas.email: {
                    (refTxtUsu.current as Components.IonInput)
                        .getInputElement()
                        .then(
                            input => input.setCustomValidity("")
                        );
                    break;
                }
            }
        }, [data])
    ;
    const onEditPass = React.useCallback((campo)=>{
        switch (campo) {
            case columnas.confirmContrasena: {
                getInputFromElement(refTxtPassword)
                    .then(
                        input => input.setCustomValidity("")
                    );
                break;
            }
            case columnas.email: {
                getInputFromElement(refTxtUsu)
                    .then(
                        input => input.setCustomValidity("")
                    );
                break;
            }
        }

    },[]);
    const resetCampos = React.useCallback(() => {
        setCampos(defaultState);
        set(data, defaultStateData);
    }, [data, setCampos]);

    const onCompleteDialog = React.useCallback(async (resp) => {
        if (!resp || !formProductor.current?.reportValidity())
            return;


        if (data[columnas.contrasena] !== data[columnas.confirmContrasena]) {
            (refTxtPassword.current as Components.IonInput)
                .getInputElement()
                .then(
                    input => {
                        input.setCustomValidity(intl.formatMessage({id: "contrasenasDiferentes"}));
                        input.reportValidity();
                    }
                );
            return
        }

        let dataTerminos = await CarritosTerminosTiposDatos(data[columnas.tipo]);
        dataTerminos  =removeSpaceInData(dataTerminos?.hstabla?.[0],{});
        console.log('data terminos',dataTerminos);
        data.terminos = dataTerminos?.terminos;
        return !!(await refDialog.current?.openDialog());


        // eslint-disable-next-line
    }, [data, userStore, refDialog]);

    const onFinish = React.useCallback((completed) => {
        refDialog2.current?.cancel();
        if (!completed)
            return;
        if (data[columnas.nombreContactoEmergencia]) {
            data[columnas.nombreContactoEmergencia] = (data[columnas.nombreContactoEmergencia] as string) + (data[columnas.apellidoContactoEmergencia] as string);
        }
        CarritoUsuarioActualiza(data).then((resp) => {
            onLoginGeneral(data[columnas.email], data[columnas.contrasena], router);
        })
    }, [data]);
    const onProductor = React.useCallback(() => {
        resetCampos();
        data[columnas.tipo] = 5;

        refDialog2.current?.openDialog().then(onCompleteDialog).then(async (aceptado) => {
            if (!aceptado) {
                return false;
            }
            let resp = await refDialogActivoEmpresa.current?.openDialog();
            if (!resp) {
                return refDialogSolicitudCredito.current?.openDialog(data as any).then(([acepted, data]) => {
                    return !!acepted;
                })
            }

            return true;
        }).then(onFinish);

    }, [data, onCompleteDialog, onFinish, resetCampos]);

    const onInterno = React.useCallback(() => {
        resetCampos();
        setCampos({
            rnc: true,
            apellido: false,
            contactoEmergencia: true,
            nombreEmpleado: true,
            telefonoOficina: true,
            telefonoResidencial: true,
            placa: false
        });
        data[columnas.tipo] = 8;

        refDialog2.current?.openDialog().then(onCompleteDialog).then(onFinish);

    }, [data, onCompleteDialog, onFinish, resetCampos, setCampos]);
    const onGeneralDistribuidor = React.useCallback((tipo = 1) => {
        resetCampos();
        setCampos({
            rnc: false,
            apellido: true,
            cedula: true,
            nombreRepresentante: false,
            nombreEmpresa: true,
            telefonoOficina: false,
            telefonoResidencial: true
        });
        data[columnas.tipo] = tipo;

        refDialog2.current?.openDialog().then(onCompleteDialog).then((resp) => {
            if (!resp)
                return false;
            return refDialogCondicionesDespacho.current?.openDialog().then(selected => {
                data[columnas.condicion] = selected;
                return !!selected;
            });

        }).then(onFinish);

    }, [data, onCompleteDialog, onFinish, resetCampos, setCampos]);
    const onSuplidorNacional = React.useCallback(() => {
        resetCampos();
        setCampos({
            rnc: false,
            apellido: true,
            cedula: true,
            nombreRepresentante: false,
            nombreEmpresa: true,
            telefonoOficina: false,
            telefonoResidencial: true,
            whatsapp: false
        });
        data[columnas.tipo] = 7;

        return refDialog2.current?.openDialog().then(onCompleteDialog).then((resp) => {
            if (!resp)
                return false;
            return refDialogCondicionesDespacho.current?.openDialog().then(selected => {
                data[columnas.condicion] = selected;
                return !!selected;
            });

        }).then(onFinish);

    }, [data, onCompleteDialog, onFinish, resetCampos, setCampos]);
    const onSuplidorInternacional = React.useCallback(() => {
        resetCampos();
        setCampos({
            rnc: false,
            apellido: true,
            cedula: true,
            nombreRepresentante: false,
            nombreEmpresa: true,
            telefonoOficina: false,
            telefonoResidencial: true,
            whatsapp: false,
            taxId: false,
            weChat: false,
            skype: false,
        });
        data[columnas.tipo] = 6;

        return refDialog2.current?.openDialog().then(onCompleteDialog).then((resp) => {
            if (!resp)
                return false;
            return refDialogCondicionesDespacho.current?.openDialog().then(selected => {
                data[columnas.condicion] = selected;
                return !!selected;
            });

        }).then(onFinish);

    }, [data, onCompleteDialog, onFinish, resetCampos, setCampos]);
    const onSuplidor = React.useCallback(() => {
        refDialogTipoSuplidor.current?.openDialog().then(resp => {
            if (resp === 1) {
                return onSuplidorNacional();
            } else {
                return onSuplidorInternacional();
            }
        })

    }, [onSuplidorInternacional, onSuplidorNacional]);

    const onDistribuidor = React.useCallback(() => {
        onGeneralDistribuidor(1);
    }, [onGeneralDistribuidor]);
    const onCorporativo = React.useCallback(() => {
        onGeneralDistribuidor(2);
    }, [onGeneralDistribuidor]);
    const onConsorciado = React.useCallback(() => {
        onGeneralDistribuidor(3);
    }, [onGeneralDistribuidor]);
    const onGobierno = React.useCallback(() => {
        onGeneralDistribuidor(4);
    }, [onGeneralDistribuidor]);
    const getInputFromElement = React.useCallback((ref) => {
        return Promise.resolve(
            ref?.current?.getInputElement
                ? ref.current.getInputElement()
                : ref.current
        )
    }, []);
    const onValidateCamposGeneral = React.useCallback(async (btn) => {
        if (!btn)
            return true;
        data[columnas.nombreUsuario] = data[columnas.email];
        if (!formProductor.current?.reportValidity()) {
            return false;
        }
        if (data[columnas.contrasena] !== data[columnas.confirmContrasena]) {
            getInputFromElement(refTxtPassword).then(
                input => {
                    input.setCustomValidity(intl.formatMessage({id: "contrasenasDiferentes"}));
                    input.reportValidity();
                }
            );
            return false;
        }
        let resp = await CarritoValidaUsuarioValidoParaCrear({
            contrasena: data[columnas.contrasena] as string,
            idUsuario: data[columnas.nombreUsuario] as string
        })
        if (resp) {
            getInputFromElement(refTxtUsu)
                .then(
                    input => {
                        input.setCustomValidity(intl.formatMessage({id: "usuarioYaExiste"}));
                        input.reportValidity();
                    }
                )
            return false;
        }
        return true;

    }, [data, intl]);


    const onVerifyToken = React.useCallback((token) => {
        data.token = token;
    }, [data.token]);
    const onClean = React.useCallback(() => {
        _.assign(data, {
            ...defaultStateData,
            token: data.token
        });
    }, [data]);
    const onRegister = React.useCallback(async () => {
        if (!await onValidateCamposGeneral(1))
            return;
        if (!data.token) {
            userStore.notificationService
                .addNotification(
                    intl
                        .formatMessage({id: "debe marcar la casilla de verificacion"}),
                    intl
                        .formatMessage({id: "error"})
                )
            return;
        }
        CarritoUsuarioActualiza(data).then((resp) => {
            onLoginGeneral(data[columnas.email], data[columnas.contrasena], router);

        })
    }, [data, intl, onValidateCamposGeneral, router, userStore.notificationService]);
    //#endregion
    //#region render
    if (userStore.isLoadingData) {
        return <></>;
    }
    // @ts-ignore
    return <IonPage>
        <DefaultHeader/>
        <IonHeader>
            <IonToolbar className={classes.toolbar}>
                <div slot={"start"}>
                    {/*<Avatar>
                        <img src={"/hsicon.jpeg"}/>
                    </Avatar>*/}
                    <div
                        style={{
                            width: 40,
                            height: 40,
                        }}
                    >
                        <img src={"/hidalsoft.png"}/>
                    </div>
                </div>
                <IonTitle>Register</IonTitle>
                <div slot={"end"}>
                    <LanguageSwitcherContainer/>
                </div>
                <div slot={"end"}>
                    <BtnCarrito/>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonGrid>
                <div className="containerRegister">
                    <DialogSolicitudCredito
                        ref={refDialogSolicitudCredito as any}

                    />
                    <Dialog
                        ref={refDialog2}
                        stayOpen
                        onValidate={onValidateCamposGeneral}
                        content={<IonContent>
                            <form ref={formProductor as any} className="mx-2">
                                <React.Fragment>
                                    <IonItemDivider>{intl.formatMessage({id: "General"})}</IonItemDivider>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Correo electronico"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.email]}
                                            onIonChange={onChange(columnas.email)}
                                            type={"email"}
                                            ref={refTxtUsu}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({
                                            id: campos.nombreEmpresa ? "Nombre de la empresa" : (campos.nombreEmpleado ? "Nombre del empleado" : "appModule.name")
                                        })}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.nombre]}
                                            onIonChange={onChange(columnas.nombre)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.apellido)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "Apellido"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.apellido)}
                                            value={data[columnas.primerApellido]}
                                            onIonChange={onChange(columnas.primerApellido)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.taxId)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "TAX ID"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.taxId)}
                                            value={data[columnas.primerApellido]}
                                            onIonChange={onChange(columnas.primerApellido)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.cedula)
                                    }}>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "No. de cedula"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.cedula)}
                                            value={data[columnas.noDeCeddula]}
                                            onIonChange={onChange(columnas.noDeCeddula)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.rnc)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "RNC"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.rnc)}
                                            value={data[columnas.noDeCeddula]}
                                            onIonChange={onChange(columnas.noDeCeddula)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.nombreRepresentante)
                                    }}>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Nombre del representante"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.nombreRepresentante)}
                                            value={data[columnas.nombreRepresentante]}
                                            onIonChange={onChange(columnas.nombreRepresentante)}
                                        />
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Correo de respaldo"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.correoDeRespaldo]}
                                            onIonChange={onChange(columnas.correoDeRespaldo)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.telefonoOficina)
                                    }}>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Telefono Oficina"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.telefonoOficina)}
                                            value={data[columnas.telefonoOficina]}
                                            onIonChange={onChange(columnas.telefonoOficina)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Telefono Oficina"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.telefonoMovil]}
                                            onIonChange={onChange(columnas.telefonoMovil)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.telefonoResidencial)
                                    }}>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Telefono residencial"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.telefonoResidencial)}
                                            value={data[columnas.telefonoResidencial]}
                                            onIonChange={onChange(columnas.telefonoResidencial)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.placa)
                                    }}>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "No de placa"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.placa)}
                                            value={data[columnas.placa]}
                                            onIonChange={onChange(columnas.placa)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.whatsapp)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "Whatsapp"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.whatsapp)}
                                            value={data[columnas.whatsapp]}
                                            onIonChange={onChange(columnas.whatsapp)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.weChat)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "WeChat"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.weChat)}
                                            value={data[columnas.weChat]}
                                            onIonChange={onChange(columnas.weChat)}
                                        />
                                    </IonItem>
                                    <IonItem style={{
                                        display: shouldDisplay(campos.skype)
                                    }}>
                                        <IonLabel position={"stacked"}>{intl.formatMessage({id: "Skype"})}</IonLabel>
                                        <IonInput
                                            {...isRequired(campos.skype)}
                                            value={data[columnas.skype]}
                                            onIonChange={onChange(columnas.skype)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Contrasena"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.contrasena]}
                                            onIonChange={onChange(columnas.contrasena)}
                                            clearOnEdit={false}
                                            type={"password"}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Confirmarcontraseña"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.confirmContrasena]}
                                            onIonChange={onChange(columnas.confirmContrasena)}
                                            type={"password"}
                                            clearOnEdit={false}
                                            ref={refTxtPassword}
                                        />
                                    </IonItem>
                                </React.Fragment>
                                <React.Fragment>
                                    <IonItemDivider>{intl.formatMessage({id: "pages.general.direccion"})}</IonItemDivider>

                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Nombre completo"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.nombreDireccion]}
                                            onIonChange={onChange(columnas.nombreDireccion)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Linea de direccion 1"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.direccion]}
                                            onIonChange={onChange(columnas.direccion)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "Linea de direccion 2"})}</IonLabel>
                                        <IonInput
                                            value={data[columnas.direccion2]}
                                            onIonChange={onChange(columnas.direccion2)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "pages.general.ciudad"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.ciudad]}
                                            onIonChange={onChange(columnas.ciudad)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "pages.general.EstadoProvinciaRegion"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.provincia]}
                                            onIonChange={onChange(columnas.provincia)}
                                        />
                                    </IonItem>

                                </React.Fragment>
                                {campos.contactoEmergencia && <React.Fragment>
                                    <IonItemDivider>{intl.formatMessage({id: "Contacto de emergencia"})}</IonItemDivider>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "appModule.name"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.nombreContactoEmergencia]}
                                            onIonChange={onChange(columnas.nombreContactoEmergencia)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "pages.general.lastname"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.apellidoContactoEmergencia]}
                                            onIonChange={onChange(columnas.apellidoContactoEmergencia)}
                                        />
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel
                                            position={"stacked"}>{intl.formatMessage({id: "appModule.phone"})}</IonLabel>
                                        <IonInput
                                            required
                                            value={data[columnas.telefonoContactoEmergencia]}
                                            onIonChange={onChange(columnas.telefonoContactoEmergencia)}
                                        />
                                    </IonItem>
                                </React.Fragment>}

                            </form>

                        </IonContent>}
                        buttons={[

                            {
                                title: intl.formatMessage({id: "Cancelar"}),
                                type: 0
                            },
                            {
                                title: intl.formatMessage({id: "Aceptar"}),
                                type: 1
                            }
                        ]}
                    />
                    <Dialog
                        ref={refDialog}
                        content={<IonContent>
                            <div className="container terminos">
                                <IonContent>
                                    <div slot={"fixed"}>
                                        <strong>{intl.formatMessage({id: "Terminos y Condiciones"})}</strong></div>
                                    <p dangerouslySetInnerHTML={{__html: data.terminos}}/>
                                </IonContent>

                            </div>
                        </IonContent>}
                        buttons={[

                            {
                                title: intl.formatMessage({id: "Cancelar"}),
                                type: 0
                            },
                            {
                                title: intl.formatMessage({id: "Aceptar"}),
                                type: 1
                            }
                        ]}
                    />
                    <Dialog
                        ref={refDialogActivoEmpresa}
                        content={<IonContent>
                            <div className="container">
                                <strong>{intl.formatMessage({id: "Es usted cliente activo de la empresa?"})}</strong>
                            </div>
                        </IonContent>}
                        buttons={[

                            {
                                title: intl.formatMessage({id: "no"}),
                                type: 0
                            },
                            {
                                title: intl.formatMessage({id: "si"}),
                                type: 1
                            }
                        ]}
                    />
                    <Dialog
                        ref={refDialogTipoSuplidor}

                        content={<IonContent>
                            <div className="container">
                                <strong>{intl.formatMessage({id: "Es usted un suplidor nacional o internacional?"})}</strong>
                            </div>
                        </IonContent>}
                        buttons={[
                            {
                                title: intl.formatMessage({id: "Suplidor Nacional"}),
                                type: 1
                            },
                            {
                                title: intl.formatMessage({id: "Suplidor Internacional"}),
                                type: 0
                            }
                        ]}
                    />
                    <Dialog
                        ref={refDialogCondicionesDespacho}

                        content={<IonContent>
                            <div className="container">
                                <strong>{intl.formatMessage({id: "Condiciones de despacho"})}</strong>
                            </div>
                        </IonContent>}
                        buttons={[
                            {
                                title: intl.formatMessage({id: "Credito"}),
                                type: 2
                            },
                            {
                                title: intl.formatMessage({id: "Contado"}),
                                type: 1
                            },
                            {
                                title: intl.formatMessage({id: "Consignaciones"}),
                                type: 3
                            },
                        ]}
                    />


                    {userStore.configuracion?.sistema === 24 ?
                        <Grid
                            className={'pruebaRow'}
                            spacing={3}
                            container direction={"row"}
                        >
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onProductor}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/productor.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Productor"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onDistribuidor}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/distribuidor.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Distribuidor"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onConsorciado}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/consorcio.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Consorcio"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onCorporativo}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/corporativo.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Corporativo"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onInterno}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/interno.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Interno"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onSuplidor}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/productor.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Suplidor"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <IonCard onClick={onGobierno}>
                                    <IonCardHeader>
                                        <img height={100} src={"/iconos/gobierno.png"}/>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {intl.formatMessage({id: "Gobierno"})}
                                    </IonCardContent>
                                </IonCard>
                            </Grid>
                        </Grid> :
                        <React.Fragment>
                            <form ref={formProductor as any} className="mx-2">
                                <Grid className={'pruebaRow'}
                                      spacing={3}
                                      container direction={"row"}
                                >
                                    <Grid item sm={4} xs={6}>
                                        <TextField
                                            data={data}
                                            campo={columnas.nombre}
                                            label={"appModule.name"}
                                            editable={true}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <TextField
                                            data={data}
                                            campo={columnas.primerApellido}
                                            label={"pages.general.lastname"}
                                            editable={true}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <TextField
                                            data={data}
                                            campo={columnas.email}
                                            label={"appModule.email"}
                                            type={"email"}
                                            editable={true}
                                            innerRef={refTxtUsu}
                                            onChange={onEditPass}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <TextField
                                            data={data}
                                            campo={columnas.contrasena}
                                            label={"Contrasena"}
                                            type={"password"}
                                            editable={true}
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <TextField
                                            data={data}
                                            campo={columnas.confirmContrasena}
                                            label={"Confirmarcontraseña"}
                                            editable={true}
                                            type={"password"}
                                            innerRef={refTxtPassword}
                                            onChange={onEditPass}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReCAPTCHA
                                            ref={refRecaptcha}
                                            sitekey={"6Le3E_wUAAAAACgTwBZ872H0GZ_b6yLAxV1bKKz-"}
                                            onChange={onVerifyToken}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid className={'pruebaRow'}
                                  spacing={3}
                                  container direction={"row"}
                            >
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                        onClick={onRegister}
                                    >
                                        <IntlMessages id={"Register"}/>
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        style={{width: "100%", height: "100%"}}
                                        color="primary"
                                        variant="contained"
                                        onClick={onClean}
                                    >
                                        <IntlMessages id={"Limpiar"}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>}

                </div>
            </IonGrid>

        </IonContent>
    </IonPage>
        ;
//#endregion
});

export default Tab1;
