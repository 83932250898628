// Actions
import {
    FIXED_DRAWER,
    INSIDE_THE_HEADER,
    SWITCH_LANGUAGE,
    TOGGLE_COLLAPSED_NAV,
    VERTICAL_NAVIGATION,
    WINDOW_WIDTH
} from '../../constants/ActionTypes';
import userStore from '../../mobx/UserStore';
import {Plugins} from "@capacitor/core";
import {NativeStorage} from "@ionic-native/native-storage";

const {Storage} = Plugins;
const rltLocale = ['ar'];
const initialSettings = {
    navCollapsed: false,
    drawerType: FIXED_DRAWER,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: VERTICAL_NAVIGATION,
    horizontalNavPosition: INSIDE_THE_HEADER,
    locale: {
        languageId: 'spanish',
        locale: 'es',
        name: 'Espanol',
        icon: 'es'
    }
};

export default function reducer(state = initialSettings, action = {}) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };
        case SWITCH_LANGUAGE:
            userStore.lenguaje = action.payload;
            Storage.set({key: "locale", value: JSON.stringify(action.payload)})
            return {
                ...state,
                locale: action.payload,
                isDirectionRTL: rltLocale.includes(action.payload.locale)
            };
        default: {
            userStore.lenguaje = initialSettings.locale;
            return state;

        }
    }
}

export function toggleCollapsedNav(isNavCollapsed) {
    return {
        type: TOGGLE_COLLAPSED_NAV,
        isNavCollapsed,
    };
}

export function updateWindowWidth(width) {
    return {
        type: WINDOW_WIDTH,
        width,
    };
}

export function switchLanguage(locale) {

    return {
        type: SWITCH_LANGUAGE,
        payload: locale,
    };
}
