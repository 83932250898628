/* eslint-disable no-param-reassign */
import {API_URL, BASE_API_CONF, DEFAULT_PAGE} from '../../constants/ApiConfig';
import _Get from 'lodash/get';
import _Set from 'lodash/set';
import _findKey from 'lodash/findKey';
import {isObservable, keys, toJS} from 'mobx';
import userStore from '../../mobx/UserStore';
import { v4 as uuidv4 } from 'uuid';
import createAssigner from 'lodash/_createAssigner';
import moment from 'moment';
import {lazy} from 'react';
import Fuse from "fuse.js"
import _ from "lodash";
import {
    CarritoActualiza,
    CarritoDatos,
    CarritoLogin,
    getMensajeNotification,
    getTerminales,
    TIpoDatosCarrito
} from "./RequestApi";

/**
 *
 * @param date
 * @returns {boolean}
 */
export function isDateTime(date: any) {
    try {

        if (!isNaN((+date))) {
            return false;
        }

        // @ts-ignore
        moment.suppressDeprecationWarnings = true;

        return moment(date, formatDateTypes, true)
            .isValid();
    } catch (e) {
    }
    return false;
}


export const formatDateTypes = [

    'YYYYMMDDTHH:mm:ss.SSS',
    'YYYYMMDDTHH:mm:ss.SS',
    'YYYYMMDDTHH:mm:ss.S',
    'YYYYMMDDTHH:mm:ss.SSS a',
    'YYYYMMDDTHH:mm:ss.SS a',
    'YYYYMMDDTHH:mm:ss.S a',
    'YYYYMMDDTHH:mm:ss',
    'YYYYMMDDTHH:mm:ss a',

    'YYYY-MM-DDTHH:mm:ss.SSS',
    'YYYY-MM-DDTHH:mm:ss.SS',
    'YYYY-MM-DDTHH:mm:ss.S',
    'YYYY-MM-DDTHH:mm:ss.SSS a',
    'YYYY-MM-DDTHH:mm:ss.SS a',
    'YYYY-MM-DDTHH:mm:ss.S a',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss a',

    'YYYYMMDD HH:mm:ss.SSS',
    'YYYYMMDD HH:mm:ss.SS',
    'YYYYMMDD HH:mm:ss.S',
    'YYYYMMDD HH:mm:ss.SSS a',
    'YYYYMMDD HH:mm:ss.SS a',
    'YYYYMMDD HH:mm:ss.S a',
    'YYYYMMDD HH:mm:ss',
    'YYYYMMDD HH:mm:ss a',

    'YYYY-MM-DD HH:mm:ss.SSS',
    'YYYY-MM-DD HH:mm:ss.SS',
    'YYYY-MM-DD HH:mm:ss.S',
    'YYYY-MM-DD HH:mm:ss.SSS a',
    'YYYY-MM-DD HH:mm:ss.SS a',
    'YYYY-MM-DD HH:mm:ss.S a',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss a',

    'YYYYMMDD',
    'YYYY-MM-DD',

    'HH:mm:ss.SSS',
    'HH:mm:ss.SS',
    'HH:mm:ss.S',
    'HH:mm:ss.SSS a',
    'HH:mm:ss.SS a',
    'HH:mm:ss.S a',
    'HH:mm:ss',
    'HH:mm:ss a',
];
export const defaulFormatDateTimeSql = 'YYYYMMDD HH:mm:ss.SSS';
export const defaulFormatDateTimeApi = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const lazyImport = (
    filename: any
) => (
    lazy(() => (
        import(`${filename}`)
    ))
);

export function dsTieneDatos(ds: any, soloUna = false) {
    if (ds == null) {
        return false;
    }
    if (!ds.hstabla) {
        return false;
    }
    if (ds.hstabla.length > 0) {
        return true;
    }
    if (soloUna) {
        return false;
    }
    for (let prop in ds) {
        if (ds.hasOwnProperty(prop) && ds[prop].length > 0) {
            return true;
        }
    }
    return false;
}



export function round(num: number, longitud: number) {
    let scaleUp = 10 ** longitud;
    return Math.round(num * scaleUp) / scaleUp;
}

export const assignIgnoreCase = createAssigner((dest: any, source: any) => {

    let lcKeys = Object.keys(source)
        .reduce((keys: any, k) => {
                keys[k.toLowerCase()] = k;
                return keys;
            },
            {}
        );

    for (let key in dest) {
        if (lcKeys[key.toLowerCase()]) {
            dest[key] = source[lcKeys[key.toLowerCase()]];
        }
    }

    return dest;
});


export const assignIgnoreCase2 = createAssigner((dest: any, source: any) => {

    let lcKeys = Object.keys(dest)
        .reduce((keys: any, k) => {
                keys[k.toLowerCase()] = k;
                return keys;
            },
            {}
        );

    for (let keySource in source) {
        if (lcKeys[keySource.toLowerCase()]) {
            dest[lcKeys[keySource.toLowerCase()]] = source[keySource];
        }else{
            dest[keySource] = source[keySource];
        }
    }

    return dest;
});


export function get(data: any, search: any, defaultValue = '') {

    if (!search) {
        return defaultValue;
    }
    // TODO: revisar posibles mejoras y o problemas de esta forma
    if (data && data[search])
        return data[search];
    let realKeys: any;
    if (isObservable(data)) {
        realKeys = keys(data)
            .find((key: any) => key.toLowerCase()
                .trim() === search.toLowerCase()
                .trim());
    } else {
        realKeys = _findKey(data, (value, key) => {
            return key.toLowerCase()
                .trim() === search.toLowerCase()
                .trim();
        });
    }
    /*if (defaultValue === false)
       console.log(search + ':', _Get(data, realKeys) != null ? _Get(data, realKeys, defaultValue) : defaultValue);
       */
    return _Get(data, realKeys) != null ? _Get(data, realKeys, defaultValue) : defaultValue;
}

export function getPrueba(data: any, search: any, defaultValue = '') {
    if (!search)
        return defaultValue;

    if (data && data[search])
        return data[search];
    if (data instanceof Map) {
        let keys: any = Array.from(data.keys());
        // @ts-ignore
        let realKey: any = new RegExp(search, 'i').exec(keys)[0] || search;
        return data.get(realKey) || defaultValue;
    }
    let keys: any = Object.keys(data);
    // @ts-ignore
    let realKey = new RegExp(search, 'i').exec(keys)[0] || search;
    return _Get(data, realKey) != null ? _Get(data, realKey, defaultValue) : defaultValue;
}

export const onKeyPress = (target: any, onEnter = () => '', needShiftKey = false) => (event: any) => {

    if (event.charCode === 13 && (!needShiftKey || event.shiftKey)) {
        event.preventDefault();
        if (target && target.focus) {
            target.focus();
        } else {
            console.error('target not found');
            console.trace('Target not found');
        }
        onEnter();
    }
};

export function set(data: any, field: any, value: any) {
    let realKeys;
    if (isObservable(data)) {
        realKeys = keys(data)
            .find((key: any) =>
                key.toLowerCase()
                    .trim() === field.toLowerCase()
                    .trim());
    } else {
        realKeys = _findKey(data, (value, key) => {
            return key.toLowerCase()
                .trim() === field.toLowerCase()
                .trim();
        });
    }

    if (realKeys) {
        _Set(data, realKeys, value);
    } else {
        _Set(data, field, value);
    }
    return data;
}


export function guid() {
    return uuidv4();
}

export function isNumber(val: any) {
    return /^[0-9]*$/.test(val);
}

/**
 *
 * @param data
 * @param type
 * @param numberToString
 * @param dateTimeToString
 * @param formatDate
 * @param dobleComillaSimple
 */
export function removeSpaceInData(data: any, type: any = [], numberToString = false, dateTimeToString = false, formatDate = defaulFormatDateTimeSql, dobleComillaSimple = false) {

    if (!data) {
        return type;
    }

    if (dateTimeToString) {
        data = toJS(data);
    }

    if (Array.isArray(data)) {
        data.map(dataValue => {
            for (let dataKey in dataValue) {

                if (/*dateTimeToString &&*/ dataValue[dataKey] != null && typeof dataValue[dataKey] === 'object' && !moment.isMoment(dataValue[dataKey])) {
                    dataValue[dataKey] = removeSpaceInData(dataValue[dataKey], (Array.isArray(dataValue[dataKey]) ? [] : {}), numberToString, dateTimeToString, formatDate, dobleComillaSimple);
                } else if (!dateTimeToString && typeof dataValue[dataKey] === 'string') {

                    dataValue[dataKey] = dataValue[dataKey].trim();

                    try {
                        if (isDateTime(dataValue[dataKey])) {
                            dataValue[dataKey] = moment(dataValue[dataKey], formatDateTypes);
                        }
                    } catch (e) {
                    }
                } else if (!dateTimeToString && numberToString && typeof dataValue[dataKey] === 'number') {
                    dataValue[dataKey] = dataValue[dataKey] + '';
                } else if (dateTimeToString && moment.isMoment(dataValue[dataKey])) {
                    dataValue[dataKey] = dataValue[dataKey].format(formatDate);
                } else if (dobleComillaSimple && typeof dataValue[dataKey] === 'string') {
                    dataValue[dataKey] = dataValue[dataKey].trim()
                        .replace(/'/g, '\'\'');
                }
            }
            return dataValue;
        });
        return data;
    }

    for (let dataKey in data) {
        if (/*dateTimeToString &&*/ data[dataKey] != null && typeof data[dataKey] === 'object' && !moment.isMoment(data[dataKey])) {
            data[dataKey] = removeSpaceInData(data[dataKey], (Array.isArray(data[dataKey]) ? [] : {}), numberToString, dateTimeToString, formatDate, dobleComillaSimple);
        } else if (!dateTimeToString && typeof data[dataKey] === 'string') {

            data[dataKey] = data[dataKey].trim();

            try {
                if (isDateTime(data[dataKey])) {
                    data[dataKey] = moment(data[dataKey], formatDateTypes);
                }
            } catch (e) {
            }
        } else if (!dateTimeToString && numberToString && typeof data[dataKey] === 'number') {
            data[dataKey] = data[dataKey] + '';
        } else if (dateTimeToString && moment.isMoment(data[dataKey])) {
            data[dataKey] = data[dataKey].format(formatDate);
        } else if (dobleComillaSimple && typeof data[dataKey] === 'string') {
            data[dataKey] = data[dataKey].trim()
                .replace(/'/g, '\'\'');
        }
    }

    return data;
}

/**
 *
 * @param array
 * @param busqueda
 * @param keys
 * @param precision
 * @param minLenght
 * @param id
 * @returns {*}
 */
export function busquedaInteligente<T>(array: Array<T>, busqueda: any, keys: any, precision = 0.2, minLenght = 1, id: any = undefined, customGet: any = undefined): Fuse.FuseResult<T>[] {

    if (!array || array.length <= 0) {
        return [];
    }

    if (!keys) {
        keys = Object.keys(array[0]);
    }

    if (!busqueda || !busqueda.trim()) {
        return array.map((data, index) => {
            return {item: data, refIndex: index};
        });
    }

    let options: Fuse.IFuseOptions<any> = {
        shouldSort: true,
        isCaseSensitive: false, //Para diferenciar Minusculas y Mayusculas si es verdadero...
        findAllMatches: false, //Sirve para obtener mas resultados relacionados si esta verdadero...
        threshold: precision,
        distance: 100,
        minMatchCharLength: minLenght,
        includeMatches: false,
        includeScore: false,
        useExtendedSearch: true,
        getFn: _.get,
        keys
    };
    if (customGet) {
        options.getFn = customGet;
    }

    return new Fuse(array, options).search(busqueda.trim());
}


export function focusNext(nextFocus: any) {
    setImmediate(() => nextFocus?.focus?.());
    setImmediate(() => nextFocus?.current?.focus?.());

}


export function onLoginGeneral(user, pass, router, path = DEFAULT_PAGE) {
    userStore.loadingService.show();
    CarritoLogin({
        idUsuario: user,
        contrasena: pass
    }).then(async resp => {
        if (resp.hstabla.length === 1) {
            userStore.user = resp.hstabla[0];

            if (userStore.productos.length > 0) {
                await Promise.all(userStore.productos.map((product) => {
                        CarritoActualiza({
                            codpro: product.codpro,
                            cantidad: product.cantidad,
                            coduni: product.coduni,
                            guardar: !!product.guardar,
                            precio: product.precio
                        })
                    })
                );

            }
            await CarritoDatos({
                pagina: 0,
                TipoDatos: TIpoDatosCarrito.SinGuardar,
                rowsLimit: 0
            }).then(resp=>{
                userStore.productos = removeSpaceInData(resp.hstabla, []);

            });
            getTerminales({
                // NumeroEmpresa: userStore.empresa.numemp,
                Usuario: userStore.user.id,
                // numsuc: userStore.sucursal.numsuc,
                nombreComputadora: userStore.computerName,
                // codgru: userStore.permisos ? userStore.permisos.codgru : '',
            }).then(resp => {
                userStore.loadingService.hide();
                userStore.terminal = resp.hstabla[0] ?? {};
                router.push(path);
            });

        } else {
            userStore.loadingService.hide();
            getMensajeNotification(19);
            //TODO: cambiar este mensaje
        }
    })
}
