import { action, observable } from "mobx";
import React from "react";
import {LoadingOptions} from "@ionic/core";
import {ignore} from "../libs/mobx-sync";

const defaultData: LoadingOptions = {
    message: "loading",
    duration: undefined,
    backdropDismiss: false
};

export class LoadingService {
    @observable
    @ignore
    loadingData: LoadingOptions = defaultData;
    @observable
    @ignore
    loading = false;


    @action
    show(props?: LoadingOptions) {
        if (!props) {
            props = defaultData;
        }

        this.loadingData = props;
        this.loading = true;

    }

    @action
    hide() {
        this.loading = false;
    }
}

export const LoadingContext = React.createContext(undefined) as any as React.Context<LoadingService>;
export default LoadingContext;
