import {API_URL, BASE_API_CONF, BASE_API_URL} from '../../constants/ApiConfig';
import userStore from '../../mobx/UserStore';
import {removeSpaceInData,} from './General';
import {NotificationManager} from 'react-notifications';
import swal from 'sweetalert';

const baseResponse = (res, onOk) => {
    if (res.status === 401 || res.status === 409) {
        if (!(res.url.includes('getDatosMenuUsuarioPermisos') || res.url.includes('getModulosInstalados'))) {
            userStore.logout();
            window.location.reload();
            return;
        } else {
            console.clear();
            return;
        }
    } else if (res.ok) {
        return onOk();
    }
    return res.json()
        .then((error) => {
            console.log(error);
            throw new Error(`Unable to complete this action ${error.Message}`);
        });
}
export let response = (res) => {
    return baseResponse(res, () => res.json());
};

export let responseLower = (res) => {
    return baseResponse(res, () => res.text().then(txt => txt.replace(/"([^"]+)":/g, ($0, $1) => ('"' + $1.toLowerCase() + '":'))).then(JSON.parse));
};


let catchPdf = resp => {
    return {
        ok: false,
        json: () => resp,
        blob: undefined
    };
};
let responsePdf = async (resp) => {
    if (resp.ok) {

        return {
            ok: true,
            blob: await resp.blob?.()
        };
    } else {
        return {
            ok: false,
            err: resp.json()
        };
    }
}

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
export function SalvaMenuGeneral(dtMenu: any) {
    return new Promise(function (resolve, reject) {

        if (!API_URL) {
            setImmediate(() => SalvaMenuGeneral(dtMenu)
                .then(resolve));
        } else {

            const url = `${API_URL}/SalvaMenuGeneral`;
            fetch(url, {
                ...BASE_API_CONF(),
                body: JSON.stringify(removeSpaceInData({
                    ...getDefaultParams(),
                    // numsuc: userStore.sucursal.numsuc,
                    params: {
                        dtMenu
                    }
                }, {}, false, true, undefined, true))
            })
                .then(responseLower)
                .then(resolve);
        }
    });
}

interface ResponseHS {

}

/**
 *
 * const body = {
        user:'',
        pwd:'''
    };
 * @param params
 * @returns {Promise<{hstabla:any[]}>}
 */
export function getIniciarSession(params: any) {
    const url = `${API_URL}/getIniciarSession`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        usuario:''
    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getEmpresaConfig(params) {
    const url = `${API_URL}/getEmpresaConfig`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            params: {
                TipoAplicacion: 2
            },
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        NumeroEmpresa:'',
        usuario:'',
        numsuc:'',
        nombreComputadora:'',
    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function crearTurnoUsuario(params) {
    const url = `${API_URL}/crearTurnoUsuario`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        NumeroEmpresa:'',
        codigo:'',
    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getDatosSucursalesUsuario(params) {
    const url = `${API_URL}/getDatosSucursalesUsuario`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            params: {
                TipoAplicacion: 2
            },
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        NumeroEmpresa:'',
        numsuc:'',
        Usuario:'',
        nombreComputadora:'',
   };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getConfigSystem(params) {
    const url = `${API_URL}/getConfigSystem`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        NumeroEmpresa:'',
        numsuc:'',
        Usuario:'',

    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getConfigSystem2(params) {
    const url = `${API_URL}/getConfigSystem2`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getDatosMenuUsuarioPermisos(params: string | any) {

    if (typeof params === typeof '') {
        params = {
            codgru: params
        };
    }
    const url = `${API_URL}/getDatosMenuUsuarioPermisos`;
    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify(removeSpaceInData({
            ...getDefaultParams(),
            // numsuc: userStore.sucursal.numsuc,
            ...params
        }, {}, false, true, undefined, true))
    })
        .then(response);
}

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getModulosInstalados() {
    const url = `${API_URL}/getModulosInstalados`;
    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            // numsuc: userStore.sucursal.numsuc,
        })
    })
        .then(response);

}

/**
 *
 * @param soloConTasa
 * @returns {Promise<Response | never>}
 */
export function getMonedas(soloConTasa: number = 0) {
    const url = `${API_URL}/getMonedas`;
    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: {
                soloConTasa
            }
        }),
    })
        .then(response);
}

/**
 *
 * const body = {
        NumeroEmpresa:'',
        Usuario:'',
        nombreComputadora:'',
    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getTerminales(params) {
    const url = `${API_URL}/getTerminales`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}

/**
 *
 * @param Params
 * @returns {Promise<Response | never>}
 * @constructor
 */
export function otrosPermisosGruposUsuariosDatos(Params: {
    codgruusu: string,
    nombre: string
}) {
    return fetch(`${API_URL}/otrosPermisosGruposUsuariosDatos`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            // numsuc: userStore.sucursal.numsuc,
            Usuario: userStore.user.id,
            Params
        })
    })
        .then(response);
}


/**
 *
 * const body = {
        NumeroEmpresa:'',
        Usuario:'',
        nombreComputadora:'',
    };
 * @param params
 * @returns {Promise<Response | never>}
 */
export function getTerminalesIF(params) {
    const url = `${API_URL}/getTerminalesIF`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...params,
            ...getDefaultParams()
        }),
    })
        .then(response);
}


/**
 * @param numerror
 * @param params
 * @returns {Promise<string>}
 */
export async function getMensajeNotification(numerror: number, params: string[] = []): Promise<string> {
    const url = `${API_URL}/getMensaje`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            numerror,
            ...getDefaultParams()
        }),
    })
        .then(response)
        .then((res) => {
            let mensaje = res.hstabla[0];
            let deserror: string = mensaje.deserror;
            let tipo: number = mensaje.icon;

            for (let i = 0; i < params.length; i++) {
                deserror = deserror.replace(`CAMPO#${i + 1}`, params[i]);
            }

            let mensajeFinal: string = `${mensaje.numerror} - ${deserror}`;

            switch (tipo) {
                case 7:
                case 3:
                case 1:
                    NotificationManager.error(mensajeFinal, mensaje.titulo);
                    break;
                case 2:
                case 8:
                    NotificationManager.warning(mensajeFinal, mensaje.titulo);
                    break;
                case 4:
                    NotificationManager.success(mensajeFinal, mensaje.titulo);
                    break;
                case 6:
                    NotificationManager.info(mensajeFinal, mensaje.titulo);
                    break;
                default:
                    NotificationManager.success(mensajeFinal, mensaje.titulo);
            }
            return mensajeFinal;
        });
}


/**
 *
 * @param numerror
 * @param params
 * @param onSucces
 * @param onCancel
 * @returns {Promise<boolean | never>}
 */
export async function getMensajeConfirmation(numerror: number, params: string[] = [], intl, onSucces = () => false, onCancel = () => false): Promise<string> {
    const url = `${API_URL}/getMensaje`;
    if (numerror === 11) {
        console.trace();
    }

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            numerror,
            ...getDefaultParams()
        }),
    })
        .then(response)
        .then((res) => {
            let mensaje = res.hstabla[0];
            let deserror: string = mensaje.deserror;
            let tipo: number = mensaje.icon;

            for (let i = 0; i < params.length; i++) {
                deserror = deserror.replace(`CAMPO#${i + 1}`, params[i]);
            }

            let mensajeFinal: string = `${mensaje.numerror} - ${deserror}`;

            let opciones: any = {
                title: mensaje.titulo,
                text: mensajeFinal,

                buttons: true,
                dangerMode: true,
            };
            switch (tipo) {
                case 7:
                case 3:
                case 1:
                    opciones.icon = 'error';
                    break;
                case 2:
                case 8:
                    opciones.icon = 'warning';
                    break;
                case 4:
                    opciones.icon = 'success';
                    break;
                case 6:
                    opciones.icon = 'info';
                    break;
                default:
                    opciones.icon = 'success';
            }
            switch (mensaje.buton) {
                case 3:
                    opciones.buttons = {

                        Si: {
                            text: 'Si',
                            value: 1
                        },
                        No: {
                            text: 'No',
                            value: 0
                        },
                        cancelar: {
                            text: 'Cancelar',
                            value: -1
                        }
                    };
                    break;
                case 4:
                    opciones.buttons = {
                        Si: {
                            text: 'Si',
                            value: 1
                        },
                        No: {
                            text: 'No',
                            value: 0
                        }
                    };
                    break;
                case 1:
                    opciones.buttons = {
                        Si: {
                            text: 'Aceptar',
                            value: 1
                        },
                        No: {
                            text: 'Cancelar',
                            value: -1
                        }
                    };
                    break;
                default:
                    opciones.buttons = {
                        aceptar: {
                            Text: 'Aceptar',
                            value: 1
                        }
                    };
            }
            return swal(opciones);
        });
}

/**
 *
 */
export function getDepartamentos(): Promise<{
    hstabla?: Array<{
        coddep: string,
        desdep: string
    }>
}> {
    const url = `${API_URL}/getDepartamentos`;
    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),

            Usuario: userStore.user.id
        }),
    })
        .then(responseLower);
}

/**
 *
 * @param filtro
 * @param pagina
 * @param rowsLimit
 * @param codpro
 * @param coduni
 * @param estado
 * @param incluirDirecciones
 * @param esMantenimiento
 * @param campo
 * @param params
 * @param controller
 * @returns {Promise<Response | never>}
 */
export function getProductosNew(
    {
        filtro = '',
        pagina = 1,
        rowsLimit = 50,
        codpro = '',
        coduni = '',
        estado = 1,
        incluirDirecciones = false,
        esMantenimiento = false,
        campo = 'codpro',
        params = {}
    }, controller?: AbortController
) {
    const url = `${API_URL}/getProductosNew`;

    return fetch(url, {
        ...BASE_API_CONF(),
        signal: controller?.signal,
        body: JSON.stringify(removeSpaceInData({
            ...getDefaultParams(),
            NumeroPagina: pagina,
            TamanoPaginacion: rowsLimit,
            codpro,
            coduni,
            estado,
            filtro,
            incluirDirecciones,
            esMantenimiento,
            campo,
            subirpagina: 1,
            Params: {
                ...params,
                subirpagina: 1
            }

        }, {}, false, true, undefined, true))
    })
        .then(responseLower);
}

export enum TIpoDatosCarrito {
    Todos,
    SinGuardar,
    Guardados
}

/**
 *
 * @param filtro
 * @param pagina
 * @param rowsLimit
 * @param codpro
 * @param coduni
 * @param estado
 * @param incluirDirecciones
 * @param esMantenimiento
 * @param campo
 * @returns {Promise<Response | never>}
 */
export function CarritoDatos(
    {
        pagina = 1,
        rowsLimit = 50,
        TipoDatos = TIpoDatosCarrito.Todos

    }
) {

    const url = `${BASE_API_URL}/Carrito/CarritoDatos`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify(removeSpaceInData({
            ...getDefaultParams(),
            NumeroPagina: pagina,
            TamanoPaginacion: rowsLimit,
            Params: {
                pagina,
                registrosporpagina: rowsLimit,
                TipoDatos: TipoDatos,
                idusuario: userStore.user.id
            }
        }, {}, false, true, undefined, true))
    })
        .then(responseLower);
}


export function CarritoActualiza(
    params: {
        codpro: string,
        coduni: string,
        cantidad: number,
        precio: number,
        guardar: boolean
    }
) {

    const url = `${BASE_API_URL}/Carrito/CarritoActualiza`;

    return fetch(url, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: {
                ...params,
                idusuario: userStore.user.id
            }
        })
    })
        .then(responseLower);
}


export function CarritoElimina(
    params: {
        codpro: string,
        coduni: string
    }
) {
    return fetch(`${BASE_API_URL}/Carrito/CarritoElimina`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: {
                ...params,
                idusuario: userStore.user.id
            }
        })
    })
        .then(responseLower);
}

export function CarritoValidaUsuarioValidoParaCrear(
    params: {
        idUsuario: string,
        contrasena: string
    }
) {
    return fetch(`${BASE_API_URL}/Carrito/CarritoValidaUsuarioValidoParaCrear`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: params
        })
    })
        .then(responseLower);
}

export function CarritoLogin(
    params: {
        idUsuario: string,
        contrasena: string
    }
) {
    return fetch(`${BASE_API_URL}/Carrito/CarritoLogin`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: params
        })
    })
        .then(responseLower);
}


export function CarritoUsuarioActualiza(params: any) {
    return fetch(`${BASE_API_URL}/Carrito/CarritoUsuarioActualiza`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: params
        })
    })
        .then(responseLower);
}


export function Hs_Agrega_Cotizacion(idUsuario: any) {

    return fetch(`${BASE_API_URL}/Carrito/Hs_Agrega_Cotizacion`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: {
                id: idUsuario ?? userStore.user.id
            }
        })
    }).then(resp => {
        return resp;
    }).catch(catchPdf).then(responsePdf)
}

export enum TipoCarritoCuentasCobrar {
    EstadoDeCuentas = 1,
    FacturasVencidas = 4,
    HistorialDeCompras = 5,
}

export async function CarritoCuentasCobrar(tipo = TipoCarritoCuentasCobrar.EstadoDeCuentas, idUsuario?: any) {

    return fetch(`${BASE_API_URL}/Carrito/CarritoCuentasCobrar`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            Params: {
                id: idUsuario ?? userStore.user.id,
                Tipo: tipo,
                identificadorcarrito: await userStore.uniqueDeviceId
            }
        })
    }).catch(catchPdf).then(responsePdf)
}


export function getNcf(tipo = 1) {
    return fetch(`${API_URL}/getNcf`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            tipo: tipo
        })
    })
        .then(responseLower);
}

export function spsolicitudActualiza(params) {
    return fetch(`${BASE_API_URL}/Carrito/spsolicitudActualiza`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            params
        })
    })
        .then(responseLower);
}


export function carritoConfiguracion() {
    return fetch(`${BASE_API_URL}/Carrito/carritoConfiguracion`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
        })
    })
        .then(responseLower);
}

export function DatosPagoPagPaypal() {
    return fetch(`${BASE_API_URL}/Carrito/DatosPagoPag`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            idClientePago: 1
        })
    })
        .then(responseLower);
}

export function createOrderPaypal() {
    return fetch(`${BASE_API_URL}/Carrito/CreatePayment`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
        })
    })
        .then(responseLower);
}

export function GetOrderDetailsPaypal(orderId: string) {
    return fetch(`${BASE_API_URL}/Carrito/GetOrder`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            params: {
                orderId
            }
        })
    })
        .then(responseLower);
}

export enum CarritosTerminosTipos {
    Distribuidor = 1,
    Corporativo,
    Consorciado,
    Gobierno,
    Producto,
    SuplidorInternacional,
    SuplidorNacional,
    Interno,
}

export function CarritosTerminosTiposDatos(tipo) {
    return fetch(`${BASE_API_URL}/Carrito/CarritosTerminosTiposDatos`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
            params: {
                tipo
            }
        })
    })
        .then(responseLower);
}

export function getLogoEmpresa() {
    return fetch(`${BASE_API_URL}/SistemaWeb/getLogoEmpresa`, {
        ...BASE_API_CONF(),
        body: JSON.stringify({
            ...getDefaultParams(),
        })
    }).catch(catchPdf).then(responsePdf);
}

function getDefaultParams() {
    return {
        conexion: -1,
        conexionNombre: window.location.hostname,
        // conexionNombre: "agro.app.hidalsoft.net",
        Usuario: userStore.user.id
    };
}