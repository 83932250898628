import {combineReducers} from 'redux';

import storeSettings from './ducks/settings';

export default combineReducers({
    // storeUnidad,
    // storeUnidades,
    // storeUser,
    settings: storeSettings,
});
