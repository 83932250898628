import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from '../../util/IntlMessages';
import userStore from '../../mobx/UserStore';
import {API_URL} from "../../constants/ApiConfig";
import {withRouter} from "util/withRouter";
import {useHistory} from "react-router";

const UserInfo: React.FC = () => {
    //#region variables
    let nomusu = userStore.user.nombre || "prueba hidalsoft";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    //#endregion
    //#region metodos
    const handleClick = React.useCallback((event: any) => {
        setOpen(true);
        setAnchorEl(event.currentTarget)
    }, [setAnchorEl, setOpen]);
    const handleRequestClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const onLogout = React.useCallback(() => {
        handleRequestClose();
        if (userStore.isAuthenticated)
            userStore.logout();
        history.push('/login');

    }, [handleRequestClose, history]);
    //#endregion

    return <div
        className="user-profile d-flex flex-row align-items-center"
        style={{
            background: 'linear-gradient(45deg, black, transparent)'
        }}
    >
        <Avatar
            alt="..."
            //src={`http://via.placeholder.com/150`}
            src={`${API_URL}/getFoto?name=${userStore?.user?.rutafoto?.trim()}&original=false`}
            className="user-avatar "
        />
        <div className="user-detail">
            <h4
                style={{
                    color: 'white'
                }}
                className="user-name" onClick={handleClick}>{nomusu.toUpperCase()}<i
                className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
            </h4>
        </div>
        <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
                style: {
                    width: 120,
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }}
        >

            <MenuItem onClick={onLogout}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                <IntlMessages id={userStore.isAuthenticated ? "popup.logout" : "pages.general.login"}/>
            </MenuItem>
        </Menu>
    </div>
}

const mapStateToProps = ({settings}: any) => {
    const {locale} = settings;
    return {locale};
};
export default connect(mapStateToProps)(withRouter(UserInfo));

