import React from "react";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router";

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let history = useHistory();
        let params = useParams();
        let match = useRouteMatch()
        return (
            <Component
                {...props}
                location={location}
                match={match}
                history={history}
                router={{location, history, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}