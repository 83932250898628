import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Firebase, {FirebaseContext, userStoreContext} from "./services/Firebase";
import NotificationContext, {NotificationService} from './services/NotificationService';
import {dom, library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons';
import {AsyncTrunk} from "./libs/mobx-sync";
import userStore from "./mobx/UserStore";
import configureStore from './store';
// import 'mobx-react-lite/batchingForReactDom'
import * as ApiConfig from './constants/ApiConfig';

import {Provider} from 'react-redux';
import {IonPage} from '@ionic/react';
import LoadingContext, {LoadingService} from "./services/LoadingController";
import {removeSpaceInData} from "./util/api/General";
import {carritoConfiguracion, DatosPagoPagPaypal} from "./util/api/RequestApi";
import {Storage} from "@capacitor/storage";

declare global {
    interface Array<T> {
        remove(o: T): boolean;

        replace(o: Array<T>): boolean;

        find(predicate: (item, index, array) => boolean): T | null;

        clear(): void;
    }
}


library.add(fab, fas, far)
dom.watch()
let API_URL = ApiConfig.API_URL;

const trunk = new AsyncTrunk(userStore, {
    storage: {
        getItem(key: string): Promise<string | null> {
            return Storage.get({key}).then((resp) => resp.value);
        },
        removeItem(key: string): Promise<void> {
            return Storage.remove({key});
        },
        setItem(key: string, value: string): Promise<void> {
            return Storage.set({key, value});
        }
    }
});


export const store = configureStore();

function render() {
    ReactDOM.render(
        <IonPage>

            <Provider store={store}>
                <userStoreContext.Provider value={userStore}>
                    <FirebaseContext.Provider value={userStore.firebase}>
                        <NotificationContext.Provider value={userStore.notificationService}>
                            <LoadingContext.Provider value={userStore.loadingService}>
                                <App/>
                            </LoadingContext.Provider>
                        </NotificationContext.Provider>
                    </FirebaseContext.Provider>
                </userStoreContext.Provider>
            </Provider>
        </IonPage>, document.getElementById('root'));

}
async function loadScript(src:string, id:string){
    const existingScript = document.getElementById(id);
    if(!existingScript){
        return new Promise<void>(resolve=>{
            const script = document.createElement('script');
            script.src = src;
            script.id = id;
            document.body.appendChild(script);
            script.onload = () => {
                resolve();
            };
        });
    }
}
trunk.init().then(() => {
    setImmediate(() => {
        async function datos() {
            let resp = await carritoConfiguracion()
            userStore.configuracion = removeSpaceInData(resp.hstabla[0], {});
            userStore.notificationService = new NotificationService();
            userStore.loadingService = new LoadingService();
            userStore.firebase = new Firebase();
            await DatosPagoPagPaypal().then((resp: {hstabla: {id:string,secre:string}[]})=>{
                if(resp.hstabla && resp.hstabla.length > 0){
                    return loadScript("https://www.paypal.com/sdk/js?client-id=" + resp.hstabla[0].id,"paypalScript");
                }
            })
            //TODO: cargar paypal

            render()

            /*
              let params = {
                // NumeroEmpresa: userStore.empresa.numemp,
                Usuario: userStore.user.id,
                // numsuc: userStore.sucursal.numsuc,
                nombreComputadora: userStore.computerName,
                // codgru: userStore.permisos ? userStore.permisos.codgru : '',
            };
             Promise.all([
                     userStore.user.id ? getDatosMenuUsuarioPermisos(params) : Promise.resolve(undefined),
                     userStore.user.id ? getModulosInstalados() : Promise.resolve(undefined),
                     userStore.user.id ? getConfigSystem(params) : Promise.resolve(undefined),
                     userStore.user.id ? getConfigSystem2(params) : Promise.resolve(undefined),
                     userStore.user.id ? getTerminales(params) : Promise.resolve(undefined),
                     userStore.user.id ? getMonedas(0) : Promise.resolve(undefined),
                     otrosPermisosGruposUsuariosDatos({
                         codgruusu: params.codgru,
                         nombre: ''
                     })
                 ])
                     .then(([menuPermisos, modulos, config, config2, terminales, monedas, otrosPermisos]) => {

                         menuPermisos = menuPermisos ? menuPermisos.hstabla.map((dt) => dt.nombre.trim()) : [];

                         runInAction(() => {
                             userStore.menuPermisos = menuPermisos;
                             userStore.modulos = modulos ? modulos.hstabla : [];

                             userStore.configSystem = (config && config.hstabla) ? config.hstabla[0] : {};
                             userStore.configSystem2 = (config2 && config2.hstabla) ? config2.hstabla[0] : {};
                             userStore.terminal = (terminales && terminales.hstabla) ? terminales.hstabla[0] : {};

                             userStore.otrosPermisos = (otrosPermisos && otrosPermisos.hstabla) ? otrosPermisos.hstabla : [];

                             if ((monedas && monedas.hstabla)) {
                                 userStore.monedanac = find(monedas.hstabla, item => item.tipmoneda.trim() === (userStore.empresa.codmoneda || '00001').trim());
                                 userStore.monedaint = find(monedas.hstabla, item => item.tipmoneda.trim() === (userStore.empresa.codmoneda2 || '00002').trim());
                             }

                             if (!userStore.monedanac)
                                 userStore.monedanac = {
                                     tipmoneda: '00001',
                                     desmoneda: 'Peso',
                                     simbolo: 'RD$',
                                     tasa: 1,
                                 };

                             if (!userStore.monedaint)
                                 userStore.monedaint = {
                                     tipmoneda: '00002',
                                     desmoneda: 'Dollar',
                                     simbolo: 'USD$',
                                     tasa: 0,
                                 };

                             userStore.isLoadingData = false;
                         });

                         render();
                     });
            */
        }

        fetch('./config.json')
            .then((data) => data.json())
            .then((data) => {
                API_URL = data.API_URL;
            })
            .catch((ex) => {
                API_URL = 'https://m.vitico.space/api/SistemaWeb';
            })
            .then(() => {
                setImmediate(() => datos());
            });


    })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
