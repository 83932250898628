import React, {useState} from 'react';
import {useHistory} from "react-router";
import "./login.css";
import {reaction} from "mobx";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import Dialog, {TypeDialog} from "../../components/Dialog";
import {DefaultHeader} from "../../App";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";
import {Avatar, Button, Grid} from "@material-ui/core";
import IntlMessages from "../../util/IntlMessages";
import userStore from "../../mobx/UserStore";
import {observer, useLocalStore} from "mobx-react-lite";
import {useIntl} from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import {useSelector} from "react-redux";
import {DEFAULT_PAGE} from "../../constants/ApiConfig";
import {onLoginGeneral} from "../../util/api/General";
import LanguageSwitcherContainer from "../../components/LanguageSwitcher";
import BtnCarrito from "../../components/BtnCarrito";

const targetUrl = DEFAULT_PAGE;
const columnas = {
    user: "user",
    pass: "pass",
    nombre: "nombre",
    email: "email",
    tipo: "tipo"
};
const useStyles = makeStyles((theme: Theme) => ({
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500]
    },
    toolbar: {
        padding: `0 ${theme.spacing(5)}px`,
    },
    searchButton: {}
}));
const Tab1: React.FC = observer((props: any) => {
    //#region variables
    const refDialogForgotPassword = React.useRef<TypeDialog>();
    const router = useHistory();
    const [data, setData] = useState({
        [columnas.user]: "",
        [columnas.pass]: "",
        token: ""
    });
    const intl = useIntl();
    const classes = useStyles();
    const local = useSelector((store) => {
        return store.settings.locale.locale;
    })

    //#endregion
    //#region life cycle
    React.useEffect(() => {
//TODO: revisar para redireccionar
        return reaction(() => userStore.isLoadingData, () => {
            if (userStore.isLoadingData)
                return;
            if (userStore.isAuthenticated)
                router.push(targetUrl);
        }, {fireImmediately: true});
        // eslint-disable-next-line
    }, [router]);
    //#endregion
    //#region metodos


    const onChange = React.useCallback((campo) => (event: CustomEvent) => {
        setData(data => ({...data, [campo]: event.detail.value}));
    }, [setData]);

    const goToRegister = React.useCallback((event: any) => {
        router.push("/register");
    }, [router]);


    const onLogin = React.useCallback((event: any) => {
        event?.preventDefault?.();
        let direction = targetUrl;
        if (props.location.state && props.location.state.from) {
            direction = props.location.state.from.pathname;
        }
        onLoginGeneral(data[columnas.user], data[columnas.pass], router, direction);
        // router.push("/register");
    }, [data, props.location.state, router]);


    const onForgotPassword = React.useCallback(() => {
        refDialogForgotPassword.current?.openDialog().then(resp => {
            console.log("resp", resp);
        });
    }, [intl]);
    const onClickBtnDialogForgot = React.useCallback(async (type) => {
        if (type === 0) {
            data[columnas.user] = '';
            return false;
        }
        data[columnas.user] = data[columnas.user].trim();
        //TODO: mandar a ejecutar en la web api para recuperar la conrasena
        return true;
    }, [data]);
    const onVerifyCaptcha = React.useCallback((token) => {
        data.token = token;
    }, [data.token]);
    //#endregion
    //#region render
    if (userStore.isLoadingData) {
        return <></>;
    }


    // @ts-ignore
    return <IonPage>
        <DefaultHeader/>
        <IonHeader>
            <IonToolbar className={classes.toolbar}>
                <div slot={"start"}>
                    {/*<Avatar>
                        <img src={"/hsicon.jpeg"}/>
                    </Avatar>*/}
                    <div
                        style={{
                            width: 40,
                            height: 40,
                        }}
                    >
                        <img src={"/hidalsoft.png"}/>
                    </div>
                </div>
                <IonTitle>{intl.formatMessage({id: "pages.general.login"})}</IonTitle>
                <div slot={"end"}>
                    <LanguageSwitcherContainer/>
                </div>
                <div slot={"end"}>
                    <BtnCarrito/>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div className="container">

                <Dialog
                    onValidate={onClickBtnDialogForgot}
                    ref={refDialogForgotPassword}
                    content={<React.Fragment>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>{intl.formatMessage({id: "Proceso de cambio de contrasena"})}</IonTitle>
                                <IonButtons slot={"end"}>
                                    <IonButton>{intl.formatMessage({id: "Close"})}</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <div className="container terminos">
                                <IonItem>
                                    <IonLabel
                                        position={"stacked"}>{intl.formatMessage({id: "Correo electronico de tu cuenta"})}:</IonLabel>
                                    <IonInput
                                        required
                                        value={data[columnas.user]}
                                        onIonChange={onChange(columnas.user)}/>
                                </IonItem>
                                <ReCAPTCHA
                                    sitekey={"6Le3E_wUAAAAACgTwBZ872H0GZ_b6yLAxV1bKKz-"}
                                    onChange={onVerifyCaptcha}
                                    hl={local}
                                />

                            </div>

                        </IonContent>
                    </React.Fragment>}
                    buttons={[
                        {
                            title: intl.formatMessage({id: "Enviar"}),
                            type: 1
                        },
                        {
                            title: intl.formatMessage({id: "Limpiar"}),
                            type: 0
                        }
                    ]}
                />

                <div className={"container"}>
                    <form>
                        <IonList>
                            <IonItem>
                                <IonLabel position={"stacked"}><IntlMessages id={"pages.general.user"}/></IonLabel>
                                <IonInput
                                    type={"email"}
                                    required
                                    value={data[columnas.user]}
                                    onIonChange={onChange(columnas.user)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position={"stacked"}><IntlMessages id={"Contrasena"}/></IonLabel>
                                <IonInput
                                    clearOnEdit={false}

                                    required
                                    type={"password"}
                                    value={data[columnas.pass]}
                                    onIonChange={onChange(columnas.pass)}/>
                            </IonItem>
                        </IonList>
                        <Grid spacing={3} container direction={"row"}>

                            <Grid item xs={4}>
                                <Button
                                    style={{width: "100%", height: "100%"}}
                                    color="primary"
                                    variant="contained"
                                    onClick={goToRegister}
                                >
                                    <IntlMessages id={"Register"}/>
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{width: "100%", height: "100%"}}
                                    color="primary"
                                    variant="contained"
                                    onClick={onLogin}
                                >
                                    <IntlMessages id={"pages.general.login"}/>
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{width: "100%", height: "100%"}}
                                    color="primary"
                                    variant="contained"
                                    onClick={onForgotPassword}
                                >
                                    <IntlMessages id={"Contrasena olvidada"}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                </div>
            </div>

        </IonContent>
    </IonPage>
        ;
//#endregion
});

export default Tab1;
