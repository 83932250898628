import * as mobx from 'mobx';
// @ts-ignore
import installDevTools from 'mobx-formatters';
import {ignore} from 'libs/mobx-sync';
import {uuidv4 as guid} from "../general";
import {Plugins} from "@capacitor/core";
import Firebase from "../services/Firebase";
import _ from "lodash";
import {User} from "./flowtypes";
import {NotificationService} from "../services/NotificationService";
import {LoadingService} from "../services/LoadingController";
import React from "react";
import {DialogPdfRef} from "../components/PDFViewer";
import {CarritoCuentasCobrar, TipoCarritoCuentasCobrar} from "../util/api/RequestApi";
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import ClientJsImporter from 'clientjs';

import {UniqueDeviceID} from '@ionic-native/unique-device-id';

const {ClientJS} = ClientJsImporter;

class UserStore {

    @mobx.observable user: User;
    // @mobx.observable userAutorizado: any;
    // @mobx.observable permisos: any;
    // @mobx.observable empresa: any;
    // @mobx.observable empresas: any;
    // @mobx.observable sucursal: any;
    // @mobx.observable sucursales: any;
    // @mobx.observable configSystem: any;
    // @mobx.observable configSystem2: any;
    // @mobx.observable turno: any;
    @mobx.observable terminal: any;
    @mobx.observable menuPermisos: any;
    @mobx.observable conexionActual: any;
    @ignore @mobx.observable lenguaje: any;
    @ignore @mobx.observable isLoadingData = true;
    @ignore @mobx.observable activeTable: any;
    @ignore @mobx.observable status2Datos2: any;
    @ignore menu: any;
    @ignore modulos: any;
    @ignore firebase: Firebase;
    @ignore notificationService: NotificationService;
    @ignore loadingService: LoadingService;

    @mobx.observable monedanac: any;
    @mobx.observable monedaint: any;
    @mobx.observable configuracion?: {
        danger: string
        dark: string
        light: string
        medium: string
        palabraactivarbot: string
        primario: string
        secondary: string
        sistema: number
        success: string
        tertiary: string
        warning: string
    };


    // @mobx.observable otrosPermisos: any;
    @mobx.observable productos: any[];
    refDialogPdf: React.MutableRefObject<DialogPdfRef | undefined> | undefined;

    constructor(user = {}, userAutorizado = {}, permisos = {}, empresa = {}, empresas = [], sucursal = {}, sucursales = [], configSystem = {}, configSystem2 = {}, terminal = {}, turno = {}
        , menuPermisos = [], activeTable = '', status2Datos2 = 'undefined', menu = [], modulos = [], conexionActual = "local"
        , monedanac = {}, monedaint = {}, otrosPermisos = [], productos = []) {
// @ts-ignore
        this.firebase = {} as any;
        this.loadingService = new LoadingService();
        this.notificationService = new NotificationService();
        this.user = user;
        // this.userAutorizado = userAutorizado;
        // this.permisos = permisos;
        // this.empresa = empresa;
        // this.empresas = empresas;
        // this.sucursal = sucursal;
        // this.sucursales = sucursales;
        // this.configSystem = configSystem;
        // this.configSystem2 = configSystem2;
        this.terminal = terminal;
        // this.turno = turno;
        this.activeTable = activeTable;
        this.status2Datos2 = status2Datos2;
        this.menuPermisos = menuPermisos;
        this.menu = menu;
        this.modulos = modulos;
        this.conexionActual = conexionActual;
        this.monedanac = monedanac;
        this.monedaint = monedaint;
        // this.otrosPermisos = otrosPermisos;
        this.productos = productos;
    }

    @mobx.computed
    get isAuthenticated() {
        return !!this.user.nombre;
    }

    @mobx.computed
    get uniqueDeviceId(): Promise<string> {
        return UniqueDeviceID.get()
            .then(id => id)
            .catch(() => new ClientJS().getFingerprint() + '');
        ;
    }

    @mobx.computed
    get computerName() {
        return (this.user.id) ? `${this.user.id}` : '';
        //return (new ClientJS()).getFingerprint() + '';
    }

    @mobx.action
    logout() {
        // TokenClear();

        this.productos = [];

        this.user = {};
        // this.userAutorizado = {};
        // this.permisos = {};
        // this.empresa = {};
        // this.empresas = [];
        // this.sucursal = {};
        // this.sucursales = [];
        // this.configSystem = {};
        // this.configSystem2 = {};
        this.terminal = {};
        // this.turno = {};
        // this.pais = [];
        // this.cuentasCatalogo = [];
        this.activeTable = '';
        this.menuPermisos = [];
        // this.DsInfoVoucher = {};

        this.conexionActual = "local";
        // this.paramsProceso = {};

        // this.otrosPermisos = [];


        setImmediate(() => localStorage.removeItem('__mobx_sync__'));
    }

    generateMenu(intl: { formatMessage: (arg0: { id: string; }) => any; }) {
        const onShowPdf = resp => {
            userStore.loadingService.hide();
            if (resp.ok) {
                let url = window.URL.createObjectURL(resp.blob);
                this.refDialogPdf?.current?.openDialog(url, resp.blob);
            } else {
                userStore.notificationService.addNotification(
                    "error",
                    "ha ocurrido un error al procesar los datos",
                    {});
            }
        }
        let menu = [
            {
                'key': guid(),
                'name': intl.formatMessage({id: 'Pedidos'}),
                'icon': '/assets/icons/hidalsoft.png',
                'url': '/app/productos'
            },
            {
                'key': guid(),
                'name': intl.formatMessage({id: 'Estado de cuenta'}),
                'icon': '/assets/icons/hidalsoft.png',
                'needAuth': true,
                'url': '/app/productos2',
                'onClick': (event) => {
                    this.loadingService.show();
                    CarritoCuentasCobrar().then(onShowPdf);
                    // this.refDialogPdf?.current?.openDialog()
                    return true;
                }

            },
            {
                'key': guid(),
                'name': intl.formatMessage({id: 'Facturas Vencidas'}),
                'needAuth': true,
                'icon': '/assets/icons/hidalsoft.png',
                'url': '/app/productos3',
                'onClick': (event) => {
                    this.loadingService.show();
                    CarritoCuentasCobrar(TipoCarritoCuentasCobrar.FacturasVencidas).then(onShowPdf);
                    return true;
                }
            },
            {
                'key': guid(),
                'name': intl.formatMessage({id: 'Historial de compras'}),
                'needAuth': true,
                'icon': '/assets/icons/hidalsoft.png',
                'url': '/app/productos4',
                'onClick': (event) => {
                    this.loadingService.show();
                    CarritoCuentasCobrar(TipoCarritoCuentasCobrar.HistorialDeCompras).then(onShowPdf);
                    return true;
                }
            }
        ];

        this.modulos.filter((dt: { noinstalado: any; }) => dt.noinstalado)
            .map((dt: { nombremenu: string; }) => {
                let val = menu.find((data) => data.url === dt.nombremenu);
                if (val) {
                    menu.splice(menu.indexOf(val), 1);
                }
                return dt;
            });
        return menu;
    }

    generateOtrosPermisos(intl: { formatMessage: (arg0: { id: string; }) => any; }) {

        return [

            {
                nombre: 'puedeAgregarNCFConfiguracionesEnSelect',
                texto: intl.formatMessage({id: 'PuedeagregarNCFConfiguracionesdeselecciones'}),
                activo: false
            }
        ];
    };

    updateProduct = (product: any, cant: number, isCarrito: boolean = false, colcant: string = "cantidad") => {
        let predicate = {
            codpro: _.get(product, "codpro"),
            coduni: _.get(product, "coduni")
        };
        let p = _.find(this.productos, predicate);
        if (!p) {
            p = this.productos[this.productos.push({
                ...product,
                precio: product.precio1,
                [colcant]: cant
            }) - 1];
        }
        p[colcant] = cant;
        if (p[colcant] < 0) {
            p[colcant] = 0;
        }
        if (!p[colcant]) {
            this.productos.remove(p);
        }
        return p[colcant];
    }
}

const userStore = new UserStore();
// makeInspectable(userStore);

installDevTools(mobx);
export default userStore;
export {UserStore};

