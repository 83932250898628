import React from 'react';
// @ts-ignore
import {Popover} from 'reactstrap';
import {TextField as Input, withStyles} from "@material-ui/core";
import {observer} from 'mobx-react-lite';
import {focusNext, get, isNumber, set} from '../util/api/General';
import DatePicker from 'react-datetime';
import moment from 'moment';
import userStore from '../mobx/UserStore';
import {runInAction} from 'mobx';
// @ts-ignore
import {Typeahead} from 'react-bootstrap-typeahead';
import composeRefs from "@seznam/compose-react-refs/composeRefs";
import {useIntl} from "react-intl";
import {TextFieldProps} from "@material-ui/core/TextField/TextField";
import {noop} from "lodash";
/*
// @ts-ignore
@injectIntl({
    forwardRef: true
})
export class TextInput extends React.Component<{
    style?: React.CSSProperties,
    isDate?: boolean,
    multiline?: boolean,
    label?: any,
    allowHour?: boolean | string,
    children?: React.Component,
    type?: string,
    size?: string,
    bsSize?: string,
    valid?: boolean,
    invalid?: boolean,
    tag?: Function | string,
    innerRef?: Function | string | any,
    plaintext?: boolean,
    addon?: boolean,
    className?: string,
    cssModule?: object,
    campo?: string,
    data?: object,
    editable?: boolean,
    defaultValueData?: any,
    onKeyPress?: Function,
    onEnterKey?: Function,
    onFocus?: Function,
    onBlur?: Function,
    forwardedRef?: any,
    readOnly?: boolean,
    name?: string,
    margin?: string,
    required?: boolean,
    maxLength?: number,
    id?: string,
    idFormatoDatetime?: string,
    selectedAllText?: boolean,
    value?: any,
    onChange?: Function,
    onChangeDate?: Function,
    isValidDate?: Function,
    openMenuOnFocus?: boolean,
    nextFocus?: any,
    translate?: boolean,
    rows?: number,
    allowNew?: boolean | Function,
    onClick?: Function,
    options?: Array<any>,
    labelKey?: string,
    autocomplete?: boolean,
    timeConstraints?: object,
    autoFocus?: boolean,
    placeholder?: string,
    inputRef?: any,
    onkeyDown?: any,
    inputProps?: any,
    InputProps?: any,
    intl?: any,
}, any> {


    static defaultProps = {
        idFormatoDatetime: 'pages.general.formatodatetime',
        multiline: false,
        openMenuOnFocus: false,
        allowHour: true
    };

    state = {
        showDate: false
    };

    @observable modeView: string = 'days';

    handleOnChange = (event: any) => {
        if (this.props.editable) {
            let value = '';
            if (this.props.autocomplete) {
                value = event;
            } else if (event._isAMomentObject) {
                value = event;
            } else {

                if (!event.target) {
                    value = event.value;
                } else {
                    let {type} = event.target;
                    value = event.target.value;
                    if (type === 'checkbox') {
                        value = event.target.checked;
                    } else if (type === 'number') {
                        if (value.trim() === '') {
                            value = '0';
                        }
                        if (!isNumber(value)) {
                            return;
                        }
                    }
                }
            }

            set(this.props.data, this.props.campo, value);

            this.props.onChange?.(this.props.campo, this.props.data);
        }

    };

    showDate = (event: any) => {
        this.showDateNoClick();
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    };
    showDateNoClick = () => {
        this.setState({
            showDate: true
        });
    };
    hideDate = () => {
        this.setState({
            showDate: false
        });
    };
    handleOnChangeDate = (date: any) => {

        set(this.props.data, this.props.campo, date);

        setImmediate(() => this.props.onChangeDate ? this.props.onChangeDate(this.props.campo, this.props.data, date) : undefined);
    };

    onFocus = (event: any) => {

        if (this.props.selectedAllText) {
            if (event.target && event.target === document.activeElement) {
                event.target.select();
            }
        }
        if (this.props.openMenuOnFocus && this.props.isDate && this.props.editable) {
            this.showDateNoClick();
        }
        runInAction('onFocus', () => userStore.activeTable = '');
        this.props.onFocus && this.props.onFocus(event);
    };

    onKeyPress = (event: any) => {
        if (event && event.persist) {
            event.persist();
        }

        if (event.keyCode ? event.keyCode === 13 : event.key === 'Enter') {
            if (this.props.onEnterKey) {
                this.props.onEnterKey();
            }
            if (this.props.isDate) {
                this.hideDate();
            }
            if (this.props.nextFocus && (this.props.nextFocus.focus || (this.props.nextFocus.current && this.props.nextFocus.current.focus))) {
                setImmediate(() => this.props.nextFocus.focus ? this.props.nextFocus.focus() : this.props.nextFocus.current.focus());

                if (event && event.preventDefault) {
                    event.preventDefault();
                }
            }
            /!*
             if (this.props.nextFocus && this.props.nextFocus.focus) {
                setImmediate(() => this.props.nextFocus.focus());

                if (event && event.preventDefault) {
                    event.preventDefault();
                }
            }
            *!/
            /!*
             setImmediate(() => this.props.nextFocus?.focus?.() && this.props.nextFocus?.current?.());

            if (event && event.preventDefault) {
                event.preventDefault();
            }
            *!/
        }

        this.props.onKeyPress && this.props.onKeyPress(event);
    };

    disableOnChange() {
        return false;
    };

    render() {
        let {
            campo,
            data,
            inputRef,
            onkeyDown,
            inputProps,
            InputProps, intl,
            editable,
            defaultValueData = this.props.isDate ? moment(new Date()) : '',
            isDate = false,

            label,
            multiline,
            idFormatoDatetime,

            selectedAllText,
            openMenuOnFocus,
            nextFocus,
            translate,
            onChangeDate,
            onEnterKey,
            allowNew, options, labelKey, autocomplete,
            timeConstraints = {},
            isValidDate,
            innerRef,
            forwardedRef,
            allowHour,
            ...others
        } = this.props;

        let props = {
            ...others,
            type: multiline ? 'textarea' : others.type,
            ...inputProps,
            ...InputProps,
            onFocus: this.onFocus,
            onKeyPress: this.onKeyPress,// Todo: revisar onKeyUp o onKeyPress
            innerRef: composeRefs(forwardedRef, innerRef, inputRef)
        };
        if (campo) {
            props = {
                id: campo,
                ...props,
                value: campo ? (isDate ? moment(get(data, campo, defaultValueData))
                    .format(intl.formatMessage({id: idFormatoDatetime})) : get(data, campo, defaultValueData)) : others.value,
                onChange: campo ? (isDate ? this.disableOnChange : this.handleOnChange) : others.onChange,
                onClick: isDate && this.props.editable ? this.showDate : others.onClick
            };
        }


        return (
            <React.Fragment>
                {label && <label>{translate ? <IntlMessages id={label}/> : label}</label>}

                {autocomplete ?
                    <Typeahead
                        {...props}
                        allowNew={allowNew}
                        options={options}
                        labelKey={labelKey}
                        flip
                        positionFixed
                        selected={props.value ? props.value : undefined}
                    />
                    :
                    <Input
                        {...props}

                        //innerRef={this.innerRef}
                    />}
                {isDate && <Popover
                    //ref={ref=>this.popOverFech = ref}
                    isOpen={this.state.showDate} target={props.id}
                    toggle={this.hideDate}
                >
                    <div style={{
                        height: 272,
                        display: 'flex',
                        alignItems: (this.modeView === 'days' || this.modeView === 'time') ? 'flex-end' : undefined,
                        paddingBottom: 3,
                    }}>
                        <DatePicker
                            input={false}
                            disableOnClickOutside
                            open={true}
                            value={get(data, campo, defaultValueData)}
                            onChange={this.handleOnChangeDate}
                            timeConstraints={timeConstraints}
                            // TODO: isValidDate={isValidDate}
                            onViewModeChange={mode => this.modeView = mode.toLowerCase()}
                            timeFormat={allowHour}
                            // dateFormat={this.props.intl.formatMessage({id: 'pages.general.formatodate'})}
                            // timeFormat={this.props.intl.formatMessage({id: 'pages.general.formatotime'})}

                            className="col row m-0 no-padding"/>
                    </div>
                </Popover>}
            </React.Fragment>
        );
    }
}*/

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: 'var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3880ff',
        },
    },
})(Input);
const TextField = observer<{
    style?: React.CSSProperties,
    isDate?: boolean,
    multiline?: boolean,
    label?: any,
    allowHour?: boolean | string,
    children?: React.Component,
    type?: string,
    size?: string,
    bsSize?: string,
    valid?: boolean,
    invalid?: boolean,
    tag?: Function | string,
    innerRef?: Function | string | any,
    plaintext?: boolean,
    addon?: boolean,
    className?: string,
    cssModule?: Object,
    campo?: string,
    data?: Object,
    editable?: boolean,
    defaultValueData?: any,
    onKeyPress?: Function,
    onEnterKey?: Function,
    onFocus?: Function,
    onBlur?: Function,
    forwardedRef?: any,
    readOnly?: boolean,
    name?: string,
    margin?: string,
    required?: boolean,
    maxLength?: Number,
    id?: string,
    idFormatoDatetime?: string,
    selectedAllText?: boolean,
    value?: any,
    onChange?: Function,
    onChangeDate?: Function,
    isValidDate?: Function,
    openMenuOnFocus?: boolean,
    nextFocus?: any,
    translate?: any,
    rows?: Number,
    allowNew?: boolean | Function,
    onClick?: Function,
    options?: Array<any>,
    labelKey?: string,
    autocomplete?: boolean,
    timeConstraints?: Object,
    autoFocus?: boolean,
    placeholder?: string,
    inputRef?: any,
    onkeyDown?: any,
    inputProps?: any,
    InputProps?: any,
} & TextFieldProps>(function (passedProps, ref) {
    //#region variables
    const [state, setState] = React.useState({
        showDate: false
    });
    const [modeView, setModeView] = React.useState('days');
    const intl = useIntl();
    //#endregion

    //#region metodos
    const handleOnChange = React.useCallback((event: any) => {
        if (passedProps.editable) {
            let value = '';
            if (passedProps.autocomplete) {
                value = event;
            } else if (event._isAMomentObject) {
                value = event;
            } else {

                if (!event.target) {
                    value = event.value;
                } else {
                    let {type} = event.target;
                    value = event.target.value;
                    if (type === 'checkbox') {
                        value = event.target.checked;
                    } else if (type === 'number') {
                        if (value.trim() === '') {
                            value = '0';
                        }
                        if (!isNumber(value)) {
                            return;
                        }
                    }
                }
            }

            set(passedProps.data, passedProps.campo, value);

            // @ts-ignore
            passedProps.onChange?.(passedProps.campo, passedProps.data);
        }

    }, [passedProps.autocomplete, passedProps.campo, passedProps.data, passedProps.editable, passedProps.onChange]);
    const showDateNoClick = React.useCallback(() => {
        setState({
            showDate: true
        });
    }, []);
    const showDate = React.useCallback((event: any) => {
        showDateNoClick();
        if (passedProps.onClick) {
            passedProps.onClick(event);
        }
    }, [passedProps, showDateNoClick]);
    const hideDate = React.useCallback(() => {
        setState({
            showDate: false
        });
    }, []);
    const handleOnChangeDate = React.useCallback((date: any) => {

        set(passedProps.data, passedProps.campo, date);

        setImmediate(() => passedProps.onChangeDate ? passedProps.onChangeDate(passedProps.campo, passedProps.data, date) : undefined);
    }, [passedProps]);
    const onFocus = React.useCallback((event: any) => {

        if (passedProps.selectedAllText) {
            if (event.target && event.target === document.activeElement) {
                event.target.select();
            }
        }
        if (passedProps.openMenuOnFocus && passedProps.isDate && passedProps.editable) {
            showDateNoClick();
        }
        runInAction('onFocus', () => userStore.activeTable = '');
        passedProps.onFocus && passedProps.onFocus(event);
    }, [passedProps, showDateNoClick]);
    const onKeyPress = React.useCallback((event: any) => {
        if (event && event.persist) {
            event.persist();
        }

        if (event.keyCode ? event.keyCode === 13 : event.key === 'Enter') {
            if (passedProps.onEnterKey) {
                passedProps.onEnterKey();
            }
            if (passedProps.isDate) {
                hideDate();
            }
            if (passedProps.nextFocus) {
                focusNext(passedProps.nextFocus);

                if (event && event.preventDefault) {
                    event.preventDefault();
                }
            }

        }

        passedProps.onKeyPress && passedProps.onKeyPress(event);
    }, [hideDate, passedProps]);
    const onChangeModeView = React.useCallback((mode: string, curMode: string, date: moment.Moment) => {
        setModeView(mode.toLowerCase())
        return mode;
    }, []);
    //#endregion

    //#region props
    let {
        campo,
        data,
        inputRef,
        onkeyDown,
        inputProps,
        InputProps,
        editable,
        defaultValueData = passedProps.isDate ? moment(new Date()) : '',
        isDate = false,

        label,
        idFormatoDatetime,

        selectedAllText,
        openMenuOnFocus,
        nextFocus,
        translate = true,
        onChangeDate,
        onEnterKey,
        allowNew, options, labelKey, autocomplete,
        timeConstraints = {},
        isValidDate,
        innerRef,
        forwardedRef,
        allowHour,
        ...others
    } = passedProps;

    let props = {
        ...others,
        ...inputProps,
        ...InputProps,
        onFocus: onFocus,
        onKeyPress: onKeyPress,
        InputProps: {
            inputRef: composeRefs(forwardedRef, innerRef, inputRef)
        }
    };
    if (campo) {
        props = {
            id: campo,
            ...props,
            value: campo ? (isDate ? moment(get(data, campo, defaultValueData))
                .format(intl.formatMessage({id: idFormatoDatetime})) : get(data, campo, defaultValueData)) : others.value,
            onChange: campo ? (isDate ? noop : handleOnChange) : others.onChange,
            onClick: isDate && props.editable ? showDate : others.onClick
        };
    }
    //#endregion

    return <React.Fragment>

        {autocomplete ?
            <Typeahead
                {...props}
                allowNew={allowNew}
                options={options}
                labelKey={labelKey}
                flip
                positionFixed
                selected={props.value ? props.value : undefined}

            />
            :
            <CssTextField
                {...props}
                label={label ? (translate ? intl.formatMessage({id: label}) : label) : undefined}
                style={{
                    width: "100%",
                    ...props.style,

                }}
                InputLabelProps={{
                    shrink: true,
                    ...props.InputLabelProps,
                }}
                //innerRef={this.innerRef}
            />}
        {isDate && <Popover
            //ref={ref=>this.popOverFech = ref}
            isOpen={state.showDate} target={props.id}
            toggle={hideDate}
        >
            <div style={{
                height: 272,
                display: 'flex',
                alignItems: (modeView === 'days' || modeView === 'time') ? 'flex-end' : undefined,
                paddingBottom: 3,
            }}>
                <DatePicker
                    input={false}
                    closeOnClickOutside={false}
                    open={true}
                    value={get(data, campo, defaultValueData)}
                    onChange={handleOnChangeDate}
                    timeConstraints={timeConstraints}
                    // TODO: isValidDate={isValidDate}
                    onBeforeNavigate={onChangeModeView}
                    timeFormat={allowHour}
                    // dateFormat={props.intl.formatMessage({id: 'pages.general.formatodate'})}
                    // timeFormat={props.intl.formatMessage({id: 'pages.general.formatotime'})}

                    className="col row m-0 no-padding"/>
            </div>
        </Popover>}
    </React.Fragment>
}, {
    forwardRef: true
})

export default TextField;
