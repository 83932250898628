// export const API_URL = 'http://142.44.236.184/api/SistemaWeb';
import userStore from "../mobx/UserStore";
export const DEFAULT_PAGE = "/app/productos";
export let API_URL = "https://m.vitico.space/api/SistemaWeb";
export let BASE_API_URL = "https://m.vitico.space/api/";
fetch('./config.json')
    .then((data) => data.json())
    .then((data) => {
        BASE_API_URL = data.BASE_API_URL;
        API_URL = data.API_URL;
    })
    .catch(() => {
        BASE_API_URL = "https://m.vitico.space/api/";
        API_URL = BASE_API_URL + '/SistemaWeb';
    });

export const BASE_API_CONF = () => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'TokenApi': userStore ? userStore.user.token : '',
        'Conexion': userStore ? userStore.conexionActual : 0,
        mode: 'no-cors',
    }
});

