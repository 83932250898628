import {userStoreContext} from "../services/Firebase";
import React from "react";
import { Redirect, Route} from "react-router";
import {IonHeader} from "@ionic/react";

export const PrivateRoute = (({component: Component, ...rest}: any) => {
    const userStore = React.useContext(userStoreContext);

    return <Route
        {...rest}
        render={props => userStore.firebase?.isLoading ? <></> : (
            (userStore.configuracion?.sistema === 24 ? userStore.isAuthenticated : true)
                ? <React.Fragment><IonHeader/><Component {...props} /></React.Fragment>
                : <Redirect to={"/login"}/>

        )
        }
    />

});
