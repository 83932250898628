import {observer, useLocalStore} from "mobx-react-lite";
import React from "react";
import Dialog, {TypeDialog} from "../../../components/Dialog";
import {IonContent} from "@ionic/react";
import {useCss, useEffectOnce} from "react-use";
import {getNcf, spsolicitudActualiza} from "../../../util/api/RequestApi";
import {removeSpaceInData} from "../../../util/api/General";
import TextInput from "../../../components/TextInput";
import SelectDynamic from "../../../components/Selects/SelectDynamic";

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";
import _ from "lodash";


const columnas = {
    nombre: "nomcli",
    rnc: "rncemp",
    cedula: "cedcli",
    direccion: "dircli",
    telefonoTrabajo: "telemp",
    celular: "celcli",
    email: "emailcli",
    nombreFamiliar: "nombreFamilia",
    telefonoFamiliar: "telefonoFamilia",
    tipoComprobante: "tipocf",
    ref: "refPersonal1",
    refTel: "refPersonal1Tel",
    ref2: "refPersonal2",
    ref2Tel: "refpersonaltel2",//TODO: falta este campo en el query de salvar
    refComercial: "refComercial1",
    refComercialTel: "refComercial1Tel",
    refComercial2: "refComercial2",
    refComercial2Tel: "refComercial1Te2",
    refBancaria: "refBanco1",
    refBancaria2: "refBanco2",
    limiteDeCredito: "limitecredito",
    observaciones: "observ",
}
const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flexFlow: "column"

    },
}))(MuiExpansionPanelDetails);
const DialogSolicitudCredito = observer((props, ref) => {
    const refDialog = React.useRef<TypeDialog>();
    const refForm = React.useRef<HTMLFormElement>();
    const data = useLocalStore(() => ({}));
    const tiposDeComprobantes = useLocalStore(() => []);
    const classNameSelect = useCss({
        "& .alert-wrapper": {
            minWidth: "370px",
        },
        '& .popover-contet': {
            width: "330px"
        }
    });
    const classNameCollapse1 = useCss({
        "&.MuiButtonBase-root": {
            backgroundColor: "#5BB4E1",
            color: "white"
        }
    });
    const classNameCollapse2 = useCss({

        "&.MuiButtonBase-root": {
            backgroundColor: "#A3C83F",
            color: "white",
        },
    });
    const classNameCollapse3 = useCss({

        "&.MuiButtonBase-root": {
            backgroundColor: "#F6BD52",
            color: "white",
        },
    });
    const classNameCollapse4 = useCss({

        "&.MuiButtonBase-root": {
            backgroundColor: "#A06BC7",
            color: "white",
        },
    });
    const classNameCollapse5 = useCss({

        "&.MuiButtonBase-root": {
            backgroundColor: "#E6D471",
            color: "white",
        },
    });
    React.useImperativeHandle(ref, () => ({
        openDialog: function (passedData: {
            user: string,
            pass: string,
            nombre: string,
            idUsuario: string,
            email: string,
            tipo: string,
            confirmContrasena: string,
            contrasena: string,
            nombreDireccion: string,
            direccion: string,
            direccion2: string,
            ciudad: string,
            estado: string,
            telefono: string,
            telefono2: string,
            emalrepaldo: string,
            rnccedula: string,
            apellidos: string,
            apellidoContactoEmergencia: string,
            telefonoContacto: string,
            nombreContacto: string,
            placa: string,
            condiciondespacho: string,
            whatsa: string,
            taxId: string,
            wechat: string,
            skype: string,
        }) {
            _.assign(data, {
                [columnas.nombre]: passedData.nombre,
                [columnas.cedula]: passedData.rnccedula,
                [columnas.celular]: passedData.telefono2,
                [columnas.email]: passedData.email,

            })
            return refDialog.current?.openDialog().then(async resp => {
                if (!resp)
                    return [false, {}];
                await spsolicitudActualiza(data).then(resp => {
                })
                return [true, data];
            });
        }
    }))
    useEffectOnce(() => {
        getNcf().then(resp => {
            tiposDeComprobantes.replace(removeSpaceInData(resp.hstabla, []));
        })
    })

    return <Dialog
        ref={refDialog}
        content={<IonContent>
            <form ref={refForm as any} className="mx-2">
                <React.Fragment>
                    <ExpansionPanel square>
                        <ExpansionPanelSummary classes={{
                            root: classNameCollapse1
                        }} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Datos Personales</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>


                            <TextInput
                                editable
                                data={data}
                                campo={columnas.nombre}
                                required
                                label={"appModule.name"}
                            />
                            <div className="row m-0">
                                <div className="col-md-6 col-12 p-0 pr-1">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.rnc}
                                        label={"rnc"}
                                        required
                                    />

                                </div>
                                <div className="col-md-6 col-12 p-0">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.cedula}
                                        required
                                        label={"Cedula"}
                                    />
                                </div>
                            </div>
                            <TextInput
                                editable
                                multiline
                                rows={4}
                                required
                                value={data[columnas.direccion]}
                                label={"Direccion"}
                            />
                            <div className="row m-0">
                                <div className="col-md-6 col-12 p-0 pr-2 ">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.telefonoTrabajo}
                                        required
                                        label={"telefono trabajo"}
                                    />

                                </div>
                                <div className="col-md-6 col-12 p-0">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.celular}
                                        required
                                        label={"Celular"}
                                    />
                                </div>
                            </div>
                            <TextInput
                                editable
                                data={data}
                                campo={columnas.email}
                                required
                                label={"Email"}
                            />

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square>
                        <ExpansionPanelSummary classes={{
                            root: classNameCollapse2
                        }} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Familia</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <TextInput
                                editable
                                data={data}
                                campo={columnas.nombreFamiliar}
                                required
                                label={"Nombre de un familiar que no viva con usted"}
                            />
                            <TextInput
                                editable
                                data={data}
                                campo={columnas.telefonoFamiliar}
                                required
                                label={"Telefono"}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <SelectDynamic
                        campo1={"codtip"}
                        campo2={"destip"}
                        editable={true}
                        label={"Tipo de comprobante"}
                        data={data}
                        opcionesDatos={tiposDeComprobantes}
                        campo={columnas.tipoComprobante}/>
                    <ExpansionPanel square>
                        <ExpansionPanelSummary classes={{
                            root: classNameCollapse3
                        }} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Referencias personales</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                            <div className="row">
                                <div className="col-6 p-0 pr-2">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.ref}
                                        required
                                        label={"1)"}
                                        translate={false}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.refTel}
                                        required
                                        label={"Telefono"}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-0 pr-2">

                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.ref2}
                                        required
                                        label={"2)"}
                                        translate={false}
                                    />
                                </div>
                                <div className="col-6 p-0 pr-2">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.ref2Tel}
                                        required
                                        value={data[columnas.ref2Tel]}
                                        label={"Telefono"}
                                    />
                                </div>
                            </div>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square>
                        <ExpansionPanelSummary classes={{
                            root: classNameCollapse4
                        }} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Referencias Comercial</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row">
                                <div className="col-6 p-0 pr-2">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.refComercial}
                                        required
                                        label={"1)"}
                                        translate={false}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.refComercialTel}
                                        required
                                        label={"Telefono"}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-0 pr-2">

                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.refComercial2}
                                        required
                                        label={"2)"}
                                        translate={false}
                                    />
                                </div>
                                <div className="col-6 p-0 pr-2">
                                    <TextInput
                                        editable
                                        data={data}
                                        campo={columnas.refComercial2Tel}
                                        required
                                        value={data[columnas.ref2Tel]}
                                        label={"Telefono"}
                                    />
                                </div>
                            </div>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel square>
                        <ExpansionPanelSummary classes={{
                            root: classNameCollapse5
                        }} aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Referencias Bancarias</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                            <TextInput
                                editable
                                data={data}
                                campo={columnas.refBancaria}
                                required
                                value={data[columnas.refBancaria]}
                                label={"1)"}
                                translate={false}
                            />
                            <TextInput
                                editable
                                data={data}
                                campo={columnas.refBancaria2}
                                required
                                value={data[columnas.refBancaria2]}
                                label={"2)"}
                                translate={false}
                            />

                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <TextInput
                        editable
                        data={data}
                        campo={columnas.limiteDeCredito}
                        required
                        label={"Limite de credito sugerido por el gerente de ventas"}
                    />

                    <TextInput
                        editable
                        data={data}
                        campo={columnas.observaciones}
                        required
                        label={"observaciones"}
                    />
                </React.Fragment>


            </form>
        </IonContent>}
        buttons={[
            {
                title: "Aceptar",
                type: 1
            },
            {
                title: "Cancelar",
                type: 0
            }
        ]}
    />
}, {forwardRef: true});

export default DialogSolicitudCredito;
