import React, {Suspense, useContext} from 'react';
import { Redirect, Route,  Switch,   useHistory} from 'react-router-dom';
import {
    getPlatforms,
    IonAlert,
    IonApp,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonLoading,
    IonPage,
    IonToolbar, setupIonicReact
} from '@ionic/react';
//@ts-ignore
import {IonReactHashRouter as IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/main.scss';
import 'react-notifications/lib/notifications.css';

import {observer, useAsObservableSource} from "mobx-react-lite";
import NotificationContext, {Notification} from "./services/NotificationService";
import {FirebaseContext, userStoreContext} from "services/Firebase";
import LoadingComponent from './components/LoadingComponent';
import { registerWebPlugin} from "@capacitor/core";
import AppContainer from "./pages/app";
import LoginPage from "./pages/login/login";
import RegisterPage from "./pages/login/Register/Register";
import { App as IonicApp } from '@capacitor/app';

import {createTheme as createMuiTheme, IconButton, MuiThemeProvider} from "@material-ui/core";
import SideNav from "./components/SideNav";
import {useDispatch, useSelector} from "react-redux";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import {IntlProvider, useIntl} from "react-intl";
import AppLocale from "./lngProvider";
import {switchLanguage, toggleCollapsedNav} from "stateManager/ducks/settings";
import {DialogPdf, DialogPdfRef} from "components/PDFViewer";
import {NotificationContainer} from 'react-notifications';
import {CarritoDatos, TIpoDatosCarrito} from "util/api/RequestApi";
import {removeSpaceInData} from "util/api/General";
import {useMount} from "react-use";
import {DEFAULT_PAGE} from "constants/ApiConfig";
import {CSSTextGenerator} from "util/theme";
import {QrCodePlugin} from "plugin-qr-code";
import {useLocation} from "react-router";
import {Storage} from "@capacitor/storage";


const NotifItem = observer(({notif}: { notif: Notification }) => {
    const onDismiss = React.useCallback(() => {
        notif.open = false;
        // eslint-disable-next-line
    }, []);
    return <IonAlert
        isOpen={notif.open}
        header={notif.title}
        message={notif.content}
        onDidDismiss={onDismiss}
        {...notif.props}
    />
});
const NotificationList = observer(() => {
    const notifications = React.useContext(NotificationContext);
    return <>
        {notifications.notifications.map((notif) =>
            <NotifItem
                key={notif.id}
                notif={notif}
            />
        )}
    </>
});
const LoadingController = observer(() => {
    const userStore = React.useContext(userStoreContext);
    const intl = useIntl();
    userStore.refDialogPdf = React.useRef<DialogPdfRef>();
    return <>
        <DialogPdf ref={userStore.refDialogPdf as any}/>
        {userStore.loadingService.loading && <IonLoading
            isOpen={true}
            {...userStore.loadingService.loadingData}
            message={intl.formatMessage({id: userStore.loadingService.loadingData.message as string})}
        />}
    </>
});

export const DeepLinkContext = React.createContext({value: ""});
setupIonicReact({
    mode: 'md'
});
const App: React.FC = observer(() => {
    const {drawerType, locale} = useSelector(({settings}) => {
        const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
        return {
            drawerType,
            navigationStyle,
            horizontalNavPosition, locale
        };
    });

    const dispatch = useDispatch();
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    const userStore = React.useContext(userStoreContext);
    const [colores, setColores] = React.useState({
            primary: userStore.configuracion?.primario || '#3880ff',
            secondary: userStore.configuracion?.secondary || '#0cd1e8',
            tertiary: userStore.configuracion?.tertiary || '#7044ff',
            success: userStore.configuracion?.success || '#10dc60',
            warning: userStore.configuracion?.warning || '#ffce00',
            danger: userStore.configuracion?.danger || '#f04141',
            dark: userStore.configuracion?.dark || '#222428',
            medium: userStore.configuracion?.medium || '#989aa2',
            light: userStore.configuracion?.light || '#f4f5f8',
        }
    );
    const deepLink = useAsObservableSource({
            value: "",
            setDeepLink: function (value: string) {
                this.value = value;
            }
        }
    );
    let applyTheme = createMuiTheme({
        overrides: {
            // Name of the component ⚛️ / style sheet
            MuiIconButton: {
                // Name of the rule
                root: {
                    padding: "0 6px"
                }
            },
        },
        palette: {
            primary: {
                // light: indigo[300],
                main: colores.primary,
                // dark: indigo[700],
                // contrastText: '#fff'
            },
            secondary: {
                // light: pink[300],
                main: colores.secondary,
                // dark: pink[700],
                // contrastText: '#fff'
            },
            error: {
                main: colores.danger
            },
            warning: {
                main: colores.warning
            },
            info: {
                main: colores.tertiary
            },
            success: {
                main: colores.success
            }
        },
        typography: {
            button: {
                fontWeight: 400,
                padding: '0 12px'
            },
        },


    });
    let Component: any;
    if (!userStore.isLoadingData) {
        let direction = DEFAULT_PAGE;
        if (!userStore.isAuthenticated && userStore.configuracion?.sistema === 24)
            direction = "/login";
        Component = <Redirect to={direction}/>;
    } else {
        Component = <IonPage><LoadingComponent/></IonPage>
    }

    useMount(async () => {
        // @ts-ignore
        document.body.style.cssText = CSSTextGenerator(applyTheme, colores);
        if (userStore.isAuthenticated) {
            await CarritoDatos({
                pagina: 0,
                TipoDatos: TIpoDatosCarrito.SinGuardar,
                rowsLimit: 0
            }).then(resp=>{
                userStore.productos = removeSpaceInData(resp.hstabla, []);
            })
        }
        userStore.isLoadingData = false;

        IonicApp.addListener("appUrlOpen", (data) => {
            deepLink.setDeepLink(data.url)
        });
        if (getPlatforms().includes("electron")) {
            const {ipcRenderer} = window.require('electron');
            ipcRenderer.on("deeplink", function (event: any, deeplink: string) {
                deepLink.setDeepLink(deeplink);
            })
        }
        userStore.firebase?.getIcon();
        Storage.get({key: "locale"}).then((resp) => {
            if (resp.value) {
                dispatch(switchLanguage(JSON.parse(resp.value)));
            } else {
                dispatch(switchLanguage(locale));

            }
        })

        registerWebPlugin(QrCodePlugin as any);
        //
        // if (getPlatforms().includes('android')) {
        //     AppUpdate.checkAppUpdate(`http://142.44.236.185:3124/app/bafae96f-ebb0-481f-92e5-c5c8ea609185/ionic`).then((value => {
        //     }))
        // }

        /*
        TODO: add to android
         new AppUpdater(this).setUpdateFrom(UpdateFrom.JSON)
       .setUpdateJSON("http://142.44.236.185:3124/app/bafae96f-ebb0-481f-92e5-c5c8ea609185/ionic2")
       .start();
         */
        //adb shell am start -a android.intent.action.VIEW -d com.hidalsoft.hsresidenical://prueba
        // eslint-disable-next-line
    });
    // @ts-ignore
    const currentAppLocale = AppLocale[locale.locale];

    return (

            <IonApp>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <MuiThemeProvider theme={applyTheme}>
                        <DeepLinkContext.Provider value={deepLink}>
                            <Suspense fallback={<IonPage>
                                <div className="centered">
                                    <div className="loader"/>
                                </div>
                            </IonPage>}>
                                <IonReactRouter>
                                    <div className={`app-container ${drawerStyle}`}>
                                        <SideNav/>
                                        <div className={"app-main-container"}>
                                            <Switch>
                                                <Route path={"/app"} component={AppContainer} />
                                                <Route path={"/login"} component={LoginPage} />
                                                <Route path={"/register"} component={RegisterPage}/>
                                                <Route path="/" exact render={()=> Component}/>
                                                <Redirect to="/"/>
                                            </Switch>
                                        </div>
                                        <NotificationContainer/>
                                        <NotificationList/>
                                        <LoadingController/>

                                    </div>
                                </IonReactRouter>
                            </Suspense>
                        </DeepLinkContext.Provider>
                    </MuiThemeProvider>
                </IntlProvider>
            </IonApp>
    );
}, {});
export default App;
export const DefaultHeader = () => {
    //#region variables
    const userStore = useContext(userStoreContext);
    const {drawerType, navCollapsed} = useSelector(({settings}) => {
        const {drawerType, navCollapsed} = settings;
        return {
            drawerType,
            navCollapsed

        };
    });

    const dispatch = useDispatch();
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    //#endregion

    const onToggleCollapsedNav = React.useCallback(() => {
        const val = !navCollapsed;
        dispatch(toggleCollapsedNav(val));
    }, [dispatch, navCollapsed]);
    let isAuth = !userStore.isLoadingData && (userStore.configuracion?.sistema !== 24 || userStore.isAuthenticated);
    const location = useLocation();
    let showBackButton = true;
    if ([DEFAULT_PAGE, "/login"].includes(location.pathname))
        showBackButton = false;


    return <IonHeader>
        <IonToolbar>
            <div slot={"start"}>
                <IonButtons>
                    {isAuth && <IconButton
                        className={`jr-menu-icon mr-3 ${drawerStyle}`}
                        aria-label="Menu"
                        onClick={onToggleCollapsedNav}>
                        <span className="menu-icon"/>
                    </IconButton>}
                    {showBackButton && <IonBackButton defaultHref={"/app/productos"}/>}
                </IonButtons>
            </div>
            <label
                style={{
                    fontSize: 'x-large'
                }}
            >
                {
                    userStore.user.nombreempresa?.trim().toLocaleUpperCase()
                }
            </label>
            <div slot={"end"}>
                {/*<HeaderIcon/>*/}
            </div>
        </IonToolbar>
    </IonHeader>
};
export const HeaderIcon = () => {
    const firebase = useContext(FirebaseContext);
    const [icon, setIcon] = React.useState(null as any);
    React.useEffect(() => {
        let isCancelled = false;
        firebase.getIcon().then((resp) => {
            if (!isCancelled) setIcon(resp);
        });
        return () => {
            isCancelled = true;
        }
    }, [firebase]);
    return <img height={56} src={icon} alt={""}/>;
};
