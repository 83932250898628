import React from "react";
import {action, computed, observable} from "mobx";
import axios from "axios";
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import userStore, {UserStore} from "../mobx/UserStore";
import {ignore} from "../libs/mobx-sync";
import {getLogoEmpresa} from "../util/api/RequestApi";
import {Storage} from "@capacitor/storage";

let Axios = axios.create({
    timeout: 5000
})
axiosRetry(Axios, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: error => {
        return isNetworkOrIdempotentRequestError(error)
            || error.code === 'ECONNABORTED';
    }
});
export type Device = {
    id: number,
    nombredispositivo: string,
    url: string,
    estado: string,
};

export default class Firebase {

    @observable
    @ignore
    isLoading = true;
    @observable
    @ignore
    userData: any;
    @ignore
    firstLoad = true;

    constructor() {
        if (this.firstLoad) {
            this.firstLoad = false;
            Storage.get({key: "user"}).then(dt => this.updateUser(dt.value ? JSON.parse(dt.value) : null)).catch((err) => {
                console.log("Error", err);
                this.updateUser(null);
            });
        }
    }

    @action
    updateUser = async (authData: any) => {
        this.userData = authData;
        this.isLoading = false;
        if (!authData)
            return;
        return this.userData;

    };

    @computed
    get isLoggedIn() {
        return !!this.userData;
    }

    async getUserDevices(): Promise<Array<Device>> {
        return []
    }

    logIn = async (data: { email: string, nombre: string, tipo: string }) => {
        return Storage.set({key: "user", value: JSON.stringify(data)}).then(() => {
            this.updateUser(data);
            return data;
        });
    };
    logOut = async () => {
        await Storage.remove({key: "user"});
        return this.updateUser(null);
    }
    register = async (email: string, password: string) => {
        return [];

    }
    forgotPassword = async (email: string) => {
        return [];
    }
    @ignore icon: any = null;

    async getIcon(): Promise<string | null> {
        if (this.icon)
            return Promise.resolve(this.icon);
        const defaultIcon = "/hsbanner.jpeg";
        let blob = await getLogoEmpresa().then(resp => {
            return resp.ok ? URL.createObjectURL(resp.blob) : defaultIcon;
        });

        this.icon = blob;

        return this.icon;
    }
}

export type Nullable<T> = T | undefined | null;
const FirebaseContext = React.createContext<Firebase>(undefined as any);
const userStoreContext = React.createContext<UserStore>(undefined as any);
export {FirebaseContext, userStoreContext};
