import React from 'react';

import LanguageItem from './LanguageItem';
import languageData from './data';
import CustomScrollbars from 'util/CustomScrollbars';
import {noop} from "libreact/lib";
import {IonPopover} from '@ionic/react';
import {useDispatch, useSelector} from "react-redux";
import {switchLanguage} from "../../stateManager/ducks/settings";

const LanguageSwitcher = ({switchLanguage, handleRequestClose}) => {
    return (
        <CustomScrollbars className="messages-list language-list scrollbar" style={{height: 80}}>
            <ul className="list-unstyled">
                {languageData.map((language, index) => <LanguageItem key={index} language={language}
                                                                     handleRequestClose={handleRequestClose}
                                                                     switchLanguage={switchLanguage}/>)}
            </ul>
        </CustomScrollbars>
    )
};
const LanguageSwitcherContainer = function () {
    const onToggle = React.useCallback((toggle) => {
        setShowPopover({open: true, event: toggle.nativeEvent});
    }, []);
    const onClose = React.useCallback(() => {
        setShowPopover({open: false, event: undefined});

    }, []);
    const {locale} = useSelector((store) => {
        const {locale} = store.settings;
        return {
            locale
        };
    });
    const dispatch = useDispatch();
    const onSwitch = React.useCallback(locale=>{
        dispatch(switchLanguage(locale));
    },[dispatch]);
    const [showPopover, setShowPopover] = React.useState<{ open: boolean, event: Event | undefined }>({
        open: false,
        event: undefined,
    });
    return <React.Fragment>
        <div className={"d-inline-block d-flex align-items-center pointer pt-1"} onClick={onToggle}>
            <i className={`flag flag-24 flag-${locale.icon}`}/>
        </div>

        <IonPopover
            isOpen={showPopover.open}
            event={showPopover.event}
            onDidDismiss={onClose}
        >
            <LanguageSwitcher
                switchLanguage={onSwitch}
                handleRequestClose={onClose}
            />
        </IonPopover>

    </React.Fragment>
        ;
}

export default LanguageSwitcherContainer;

