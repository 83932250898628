import React from 'react';
import Dialog, {TypeDialog} from "./Dialog";
import {IonContent, isPlatform} from "@ionic/react";
import {observer} from "mobx-react-lite";
import './react-pdf-viewer/src/layouts/defaultLayout.less';
import {Printer} from "@ionic-native/printer";
import Viewer, {Worker} from "./react-pdf-viewer/src";
import _ from "lodash";
import {DocumentViewer} from "@ionic-native/document-viewer";
import {File as IoFile, FileEntry} from "@ionic-native/file";
import {userStoreContext} from "../services/Firebase";
import "./pdfViewer.scss";
export type PDFViewerProps = {
    backend?: any,
    refChild?: any,
    src: string
}
export type DialogPdfRef = {
    openDialog: (pdfUrl: string, blob: any) => Promise<any>
}
export default class PDFViewer extends React.Component<PDFViewerProps> {
    viewerRef: any;
    backend: any;

    constructor(props) {
        super(props);
        this.viewerRef = React.createRef();
        this.backend = props.backend ? new props.backend() : undefined;
    }

    eventHandler = (event) => {
        console.log("detail", event.detail);
        console.log("event", event.detail);
    }

    componentDidMount() {
        const {src} = this.props;
        const element2 = this.viewerRef.current;

        const setupHammer = (element) => {
          /*  var mc = new Hammer.Manager(element, {
                recognizers: [
                    [Hammer.Pinch, {enable: true}]
                ]
            });
            let lastScale = 1;
            let handlerOut = _.throttle((event: HammerInput) => {
                if (this.props.refChild) {
                    try {
                        if (event.scale < lastScale)
                            this.props.refChild?.current?.zoomIn?.();
                        else {
                            this.props.refChild?.current?.zoomOut?.();
                        }
                        lastScale = event.scale
                    } catch (e) {
                        console.log("error", e);
                    }
                    console.log("EventType", event.type);

                }
            }, 1000, {'leading': false});
            mc.on("pinchout pinchin", handlerOut);
            mc.on('pinchend', e => {
                lastScale = 1;
            });*/

        }

        this.backend?.init(src, element2, setupHammer);
        // setupHammer(element2);

    }

    render() {
        console.log("tryied to redner");
        return (
            <div ref={this.viewerRef} id='viewer' style={{width: '100%', height: '100%'}}>
                {this.props.children}
            </div>
        )
    }
}

export const DialogPdf = observer<{
    ref: React.MutableRefObject<DialogPdfRef | any>
}>(function (props, ref) {
        const refDialog = React.useRef<TypeDialog>();
        const [pdfUrl, setPdfUrl] = React.useState("");
        const refChild = React.useRef(undefined as any);
        const userStore = React.useContext(userStoreContext);
        React.useImperativeHandle(ref, () => ({
            openDialog: async function (pdfUrl, blob) {
                function onFallback() {
                    setPdfUrl(pdfUrl);
                    return refDialog.current?.openDialog();
                }

                try {
                    let resp2 = Printer.isAvailable().then(resp => {
                        if (resp) {
                            let path = IoFile.dataDirectory;
                            IoFile
                                .writeFile(
                                    `${path}`,
                                    "tmp.pdf",
                                    blob, {
                                        replace: true
                                    })
                                .then((entry: FileEntry) => {
                                    let url = entry.toURL();
                                    if (isPlatform("ios"))
                                        return new Promise((resolve) => {
                                            DocumentViewer.viewDocument(
                                                url,
                                                "application/pdf",
                                                {
                                                    print: {
                                                        enabled: true
                                                    }
                                                },
                                                undefined, function () {
                                                    entry.remove(function () {
                                                        console.log("onDelete");
                                                    }, function (error) {
                                                        console.log("could not delete", error);
                                                    });
                                                }, function (appId, install) {
                                                    userStore.notificationService
                                                        .addNotification(
                                                            "Do you want to install the free PDF Viewer App " + appId + " for Android?",
                                                            "App no instalada"
                                                            , {
                                                                buttons: [
                                                                    {
                                                                        text: "Cancelar",
                                                                        handler: function () {
                                                                            resolve(onFallback());
                                                                        }
                                                                    },
                                                                    {
                                                                        text: "Ok",
                                                                        handler: function () {
                                                                            resolve(install());
                                                                        }
                                                                    }
                                                                ]
                                                            })
                                                })

                                        })
                                    else {
                                        return onFallback();
                                        // FileOpene
                                    }
                                })
                            // DocumentViewer.viewDocument()
                        } else {
                            return onFallback();
                        }
                    });
                    return resp2;
                } catch (e) {
                    return onFallback();
                }

            }
        }))

        return <Dialog
            ref={refDialog}
            cssClass={"modal-fullscreen"}
            content={<IonContent>
                {pdfUrl && <PDFViewer backend={PDFJs} refChild={refChild} src={pdfUrl}>
                    {/*<Worker workerUrl="/pdfjs-2.1.266-dist/build/pdf.worker.js">*/}
                    {/*    <div style={{height: '750px'}}>*/}
                    {/*        <Viewer ref={refChild} fileUrl={pdfUrl}/>*/}
                    {/*    </div>*/}
                    {/*</Worker>*/}
                </PDFViewer>}
            </IonContent>}
            buttons={[
                {
                    type: 0,
                    title: "Aceptar"
                }
            ]}
        />
    }
    , {forwardRef: true})

export class PDFJs {
    init = async (source, element, setUpHammer) => {
        const iframe = document.createElement('iframe');
        let isCordova = false;
        try {
            await Printer.isAvailable().then(resp => {
                isCordova = !!resp;
            });
        } catch (e) {
            isCordova = false;
        }
        iframe.src = `/pdfjs-2.3.200-dist/web/viewer.html?iscordova=${isCordova ? 1 : 0}&file=${source}`;
        iframe.width = '100%';
        iframe.height = '100%';
   
        element.appendChild(iframe);
        // iframe.ex
        console.log("iframe", iframe);

    }
}
