import {observer, useLocalStore} from "mobx-react-lite";
import * as React from "react";
import {find, get, set} from 'lodash';
import IntlMessages from "util/IntlMessages";
import {useEffectOnce} from "react-use";
import {reaction} from "mobx";
import {focusNext} from "../../util/api/General";
import IntegrationReactSelect from "../Select";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useIntl} from "react-intl";

type OptionData = {
    data: any,
    label: string,
    value: string
}
const SelectDynamic = observer<{
    id?: string,
    className?: string,
    classNameSelect?: string,
    onChange?: Function,
    refSelect?: Function,
    openMenuOnFocus?: boolean,
    nextFocus?: Object,
    refPluss?: Object,
    styleAdd?: React.CSSProperties,
    styleDiv?: React.CSSProperties,
    styleLabel?: React.CSSProperties,
    campo1: string,
    campo2: string,
    opcionesDatos: any[],
    label: string,
    placeholder?: string,
    data: any,
    campo: string,
    editable: boolean,
    isLabelInLine?: boolean,
    required?: boolean,
    isClearable?: boolean,
    isDisabled?: boolean,
    orderByValue?: boolean,
    orderAsc?: boolean,
}>((props, ref) => {
    const [datosSelect, setDatosSelect] = React.useState([] as OptionData[]);
    const store = useLocalStore((source) => ({
        get opcionesDatos() {
            return source.opcionesDatos;
        }
    }), props)
    const intl = useIntl();
    React.useImperativeHandle(ref, () => ({

        get getCurrentValue() {
            const value = get(props.data, props.campo, '');
            return find(store.opcionesDatos, dato => (get(dato, props.campo1) + '').trim()
                .toLowerCase() === ('' + value).trim()
                .toLowerCase());
        }
    }));


    //#region lifeCicles
    useEffectOnce(() => {
        let isAsc = props.orderAsc ? 1 : -1;
        let prop = props.orderByValue ? "value" : "label";
        return reaction(() => [store.opcionesDatos], () => {
            setDatosSelect(store.opcionesDatos.map(option => ({
                value: (get(option, props.campo1) + '').trim(),
                label: get(option, props.campo2),
                data: option
            }))
                .sort((a, b) => {
                    if (a[prop] > b[prop]) {
                        return isAsc;
                    }
                    if (a[prop] < b[prop]) {
                        return -1 * isAsc;
                    }
                    return 0;
                }));
            if (store.opcionesDatos.length > 0 && !props.isClearable && !get(props.data, props.campo)) {

                setImmediate(() => {

                    let item = store.opcionesDatos[0];
                    set(props.data, props.campo, get(item, props.campo1));
                    if (props.onChange) {
                        props.onChange(get(item, props.campo1), item);
                    }
                });
            }

        }, {
            fireImmediately: true
        });
    });
    //#endregion lifeCicles

    //#region metodos
    const onKeyDown = React.useCallback((event) => {
        if (event.keyCode === 13) {
            if (props.nextFocus) {
                focusNext(props.nextFocus);
            }
        }
    }, [props.nextFocus])
    const handleOnChangeSelect = React.useCallback((event, value: OptionData, reason) => {
        if (props.editable) {
            set(props.data, props.campo, value?.value || "");
        }
        if (props.onChange) {
            props.onChange(value?.value || "", value?.data || {});
        }
        if (props.nextFocus) {
            focusNext(props.nextFocus);
        }
    }, [props])
    const getOptionLabel = React.useCallback((option) => {
        if (typeof option === "string")
            option = datosSelect.find(dt => dt.value === option);
        return option?.label ?? "";
    }, [datosSelect]);
    const getOptionSelected = React.useCallback((option, value) => {
        console.log("x1",option,value)
        return (option.value === value?.value) || (option.value == value);
    }, []);
    //#endregion metodos
    const {
        label,
        className = '',
        classNameSelect = '',
        styleLabel,
        styleAdd,
        isLabelInLine,
        id,
        data,
        campo,
        placeholder,
        refSelect,
        required,
        openMenuOnFocus,
        styleDiv,
        onChange,
        opcionesDatos,
        orderAsc,
        orderByValue,
        isClearable,
        editable,
        ...other
    } = props;
    let value = get(data, campo, null);
    return (
        <div
            style={styleDiv}
            className={`form-control-app ${isLabelInLine ? 'flex-row d-flex' : ''}  ${className}`}>

            <Autocomplete
                multiple={false}
                id={id}
                className={(isLabelInLine ? 'flex-grow-1' : '') + " " + classNameSelect}
                onChange={handleOnChangeSelect as any}
                onKeyDown={onKeyDown}
                value={value}
                placeholder={placeholder ? intl.formatMessage({id: placeholder}) : ''}
                options={datosSelect}
                openOnFocus={openMenuOnFocus}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                renderInput={(params) =>
                    <TextField
                        required={required}
                        label={intl.formatMessage({id: label})}
                        {...params}
                        InputLabelProps={{
                            shrink: true,
                            ...params.InputLabelProps
                        }}/>
                }

                {...other}
            />
        </div>
    )
}, {forwardRef: true});
const SelectDynamicBack = observer<{
    id?: string,
    className?: string,
    classNameSelect?: string,
    onChange?: Function,
    refSelect?: Function,
    openMenuOnFocus?: boolean,
    nextFocus?: Object,
    refPluss?: Object,
    styleAdd?: React.CSSProperties,
    styleDiv?: React.CSSProperties,
    styleLabel?: React.CSSProperties,
    campo1: string,
    campo2: string,
    opcionesDatos: any[],
    label: string,
    placeholder?: string,
    data: any,
    campo: string,
    editable: boolean,
    isLabelInLine?: boolean,
    required?: boolean,
    isClearable?: boolean,
    isDisabled?: boolean,
    orderByValue?: boolean,
    orderAsc?: boolean,
}>((props, ref) => {
    const datosSelect = useLocalStore(() => [] as any[]);
    const store = useLocalStore((source) => ({
        get opcionesDatos() {
            return source.opcionesDatos;
        }
    }), props)
    React.useImperativeHandle(ref, () => ({

        get getCurrentValue() {
            const value = get(props.data, props.campo, '');
            return find(store.opcionesDatos, dato => (get(dato, props.campo1) + '').trim()
                .toLowerCase() === ('' + value).trim()
                .toLowerCase());
        }
    }));


    //#region lifeCicles
    useEffectOnce(() => {
        let isAsc = props.orderAsc ? 1 : -1;
        let prop = props.orderByValue ? "value" : "label";
        return reaction(() => [store.opcionesDatos], () => {
            datosSelect.replace(store.opcionesDatos.map(option => ({
                value: (get(option, props.campo1) + '').trim(),
                label: get(option, props.campo2),
                data: option
            }))
                .sort((a, b) => {
                    if (a[prop] > b[prop]) {
                        return isAsc;
                    }
                    if (a[prop] < b[prop]) {
                        return -1 * isAsc;
                    }
                    return 0;
                }));
            if (store.opcionesDatos.length > 0 && !props.isClearable && !get(props.data, props.campo)) {

                setImmediate(() => {

                    let item = store.opcionesDatos[0];
                    set(props.data, props.campo, get(item, props.campo1));
                    if (props.onChange) {
                        props.onChange(get(item, props.campo1), item);
                    }
                });
            }

        });
    });
    //#endregion lifeCicles

    //#region metodos
    const onKeyDown = React.useCallback((event) => {
        if (event.keyCode === 13) {
            if (props.nextFocus) {
                focusNext(props.nextFocus);
            }
        }
    }, [props.nextFocus])
    const handleOnChangeSelect = React.useCallback((value, data) => {
        if (props.editable) {
            set(props.data, props.campo, value);
        }

        if (props.onChange) {
            props.onChange(value, data);
        }
        if (props.nextFocus) {
            focusNext(props.nextFocus);
        }
    }, [props])
    //#endregion metodos
    const {
        label,
        className = '',
        classNameSelect = '',
        styleLabel,
        styleAdd,
        isLabelInLine,
        id,
        data,
        campo,
        placeholder,
        refSelect,
        required,
        openMenuOnFocus,
        styleDiv,
        orderByValue,
        orderAsc,
        isClearable,
        editable,
        onChange,
        ...other
    } = props;
    let value = get(data, campo, "");

    return (
        <div
            style={styleDiv}
            className={`form-control-app ${isLabelInLine ? 'flex-row d-flex' : ''}  ${className}`}>
            {label && <label style={{
                marginRight: isLabelInLine ? 4 : 0,
                ...styleLabel
            }} htmlFor={id}><IntlMessages
                id={label}/></label>}
            <IntegrationReactSelect
                id={id}
                className={(isLabelInLine ? 'flex-grow-1' : '') + " " + classNameSelect}
                onChange={handleOnChangeSelect}
                onKeyDown={onKeyDown}
                value={value}
                placeholder={placeholder ? <IntlMessages id={placeholder}/> : ''}
                options={datosSelect}
                otherRef={refSelect}
                openMenuOnFocus={openMenuOnFocus}
                required={required}
                {...other}
            />
        </div>
    )
}, {forwardRef: true});
// @ts-ignore
SelectDynamic.defaultProps = {
    styleAdd: {},
    styleDiv: {},
    styleLabel: {},
    placeholder: 'pages.general.search',
    campo2: 'label',
    campo1: 'value',
    isClearable: true,
    orderByValue: false,
    orderAsc: false,
}

export default SelectDynamic;
