import {applyMiddleware, compose, createStore, Middleware} from 'redux';
import reducers from '../stateManager/reducers';
import {DefaultRootState} from "react-redux";

declare module "react-redux" {

    export interface Locale {
        languageId: string;
        locale: string;
        name: string;
        icon: string;
    }

    export interface Settings {
        navCollapsed: boolean;
        drawerType: string;
        width: number;
        isDirectionRTL: boolean;
        navigationStyle: string;
        horizontalNavPosition: string;
        locale: Locale;
    }

    interface DefaultRootState {
        settings: Settings;
    }
}

const middlewares: Middleware[] = [];
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore(initialState?: any) {
    return createStore(reducers, initialState,
        composeEnhancers(applyMiddleware(...middlewares)));
}

