/* eslint-disable no-param-reassign */
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import find from 'lodash/find';
import userStore from '../mobx/UserStore';

import composeRefs from '@seznam/compose-react-refs'
import Select from "react-select/base";
import {GroupBase} from "react-select";


const white = '#ffffff';
const bsBorderBlue = '#66afe9';
const bsBorderGrey = '#cccccc';
const bsBoxShadowBlue = 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);';
const bsBoxShadowGrey = 'inset 0 1px 1px rgba(0,0,0,.075);';
const bsBackgroundGrey = '#eeeeee';
const bsBackgroundOption = '#95ccfa';
const bsBackgroundOptionSelected = '#4ec0ee';
const bsControlTextColor = '#555555';
const bsControlPlaceholderColor = '#999999';
const customStyles = () => ({
    // control: () => ({
    //     display: 'flex',
    //     alignItems: 'center',
    //     border: 0,
    //     height: 'auto',
    //     background: 'transparent',
    //     '&:hover': {
    //         boxShadow: 'none'
    //     }
    // }),
    // menu: () => ({
    //     backgroundColor: 'white',
    //     boxShadow: '1px 2px 6px #888888', // should be changed as material-ui
    //     position: 'absolute',
    //     left: 0,
    //     top: 'calc(100% + 1px)',
    //     width: '100%',
    //     zIndex: 2,
    //     maxHeight: ITEM_HEIGHT * 4.5
    // }),
    // menuList: () => ({
    //     maxHeight: ITEM_HEIGHT * 4.5,
    //     overflowY: 'auto'
    // }),
    menuPortal: base => ({
        ...base,
        zIndex: 10000
    }),
    control: (base, state) => ({
        ...base,
        height: 34,
        minHeight: 34,
        maxHeight: 34,
        backgroundColor: white,
        borderWidth: 0,
        boxShadow: 'none',
        color: bsControlTextColor,
        // '&:hover > div:first-child':{
        //     borderWidth: 1,
        //     borderStyle: 'solid',
        //     borderTopLeftRadius: 4,
        //     borderBottomLeftRadius: 4,
        //     borderColor: bsBorderBlue,
        //     boxShadow:  bsBoxShadowBlue,
        // },
        '& > div:first-of-type': {

            borderWidth: 1,
            borderStyle: 'solid',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderColor: state.isFocused ? bsBorderBlue : undefined,
            boxShadow: state.isFocused ? bsBoxShadowBlue : undefined,
        }
    }),
    valueContainer: (base, state) => ({
        ...base,

        height: 34,
        minHeight: 34,
        maxHeight: 34,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey,
        '&:focus': {borderColor: bsBorderBlue},
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        boxShadow: state.selectProps.menuIsOpen ? bsBoxShadowBlue : bsBoxShadowGrey,
        padding: '2px 4px',
        // paddingLeft: 10,
    }),
    input: base => ({
        ...base,

        color: bsControlTextColor,
    }),
    singleValue: base => ({
        ...base,

        color: bsControlTextColor,
    }),
    placeholder: (base, state) => ({
        display: state.selectProps.menuIsOpen ? 'none' : 'inline',
        color: bsControlPlaceholderColor,
        paddingLeft: 3,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    clearIndicator: (base) => ({
        ...base,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderStyle: 'solid',
        borderColor: bsBorderGrey,
        backgroundColor: bsBackgroundGrey,
        height: 34,
        width: 39,
        color: bsControlTextColor,
        ':hover': {
            color: bsControlTextColor,
        },
    }),
    loadingIndicator: base => ({
        ...base,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderStyle: 'solid',
        borderColor: bsBorderGrey,
        backgroundColor: bsBackgroundGrey,
        height: 34,
        width: 39,
        padding: 8,
        margin: 0,
        color: bsControlTextColor,
        alignItems: 'center'
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        borderWidth: 1,
        display: state.isDisabled ? 'none' : (state.hasValue && state.selectProps.isClearable ? 'none' : undefined),
        borderLeftWidth: 0,
        borderStyle: 'solid',
        borderColor: bsBorderGrey,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: bsBackgroundGrey,
        height: 34,
        width: 39,
        color: bsControlTextColor,
    }),
    option: (base, state) => ({
        ...base,
        color: state.isSelected || state.isFocused ? 'black' : bsControlTextColor,
        backgroundColor: state.isSelected
            ? bsBackgroundOptionSelected
            : state.isFocused ? bsBackgroundOption : white,
        padding: '8px 6px'
    }),
    // input: base => ({
    //     ...base,
    //     color: theme.palette.text.primary,
    // }),
});

function IntegrationReactSelect({onChange, value, placeholder, options, otherRef, openMenuOnFocus, creatable = () => false, maxMenuHeight = undefined, required, onCurrentData = undefined as any, isClearable = true, isDisabled = false, ...others}) {
    let select = React.useRef<Select<any,false,GroupBase<any>>>();
    let value2 = find(options, o => o.value === value) || null;
    if (value === undefined) {
        value2 = undefined;
    }

    onCurrentData?.(
        (value2 && value2.data ? value2.data : undefined),
        (value2 && value2.value ? value2.value : ''),
        (value2 && value2.label ? value2.label : '')
    );

    return (
        <React.Fragment>
            <CreatableSelect
                isClearable={isClearable}
                isValidNewOption={creatable}
                // classes={classes}
                styles={customStyles()}
                // clearRenderer={() => <ClearIcon/>}
                menuPortalTarget={document.body}
                menuPlacement={'auto'}
                menuPosition={"fixed"}
                maxMenuHeight={maxMenuHeight}
                // components={components}
                value={value2}
                onChange={(selected, meta) => {
                    onChange((selected?.value ?? ''), (selected?.data ?? {}), meta);
                }}

                onMenuOpen={() => userStore.activeTable = ''}
                ref={composeRefs(select, otherRef as typeof select) as any}
                isDisabled={isDisabled}

                placeholder={placeholder}
                options={options}
                openMenuOnFocus={openMenuOnFocus}

                {...others}
            />
            <input
                tabIndex={-1}
                // autoComplete={props.autoComplete}
                value={value}
                onChange={() => false}
                required={required}
                style={{
                    opacity: 0,
                    // width: 0,
                    // height: 0,
                    top: 0,
                    bottom: 0,
                    position: 'absolute',
                    zIndex: -1
                }}
                onFocus={() => select?.current?.focus()}
            />
        </React.Fragment>
    );
}


export default IntegrationReactSelect;
